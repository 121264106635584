import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonRow, IonCol, IonLabel, IonInput, useIonAlert, IonGrid, IonButton, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import mapboxgl from "mapbox-gl";
import { useRef, useState } from "react";
import { Device, SiteType } from "./DataTypes";

interface DeviceCharacteristicsProps {
    siteType: SiteType | null;
    device : Device | null;
    map : React.MutableRefObject<any>;
    marker : React.MutableRefObject<mapboxgl.Marker | undefined>;
    createMarker : Function;
    renderLocationPopup : Function;
    clearDevice : Function;
    devices : Map<string, Device>
}

const DeviceCharacteristics : React.FC<DeviceCharacteristicsProps> = (props: any) => {
    const modal = useRef<HTMLIonModalElement>(null);
    const [modalOpen, setModalOpen] = useState(true);
    let device = props.device;
    let properties = [
      {name:"Name", description:"Name of the device", attribute:"name", type:"string", size:"100%", units:"", typeAttribute:null, value:device.name},
      {name:"Manufacturer", description:"Manufacturer of the device", attribute:"manufacturer", type:"string", size:"100%", units:"", typeAttribute:null, value:device.manufacturer},
      {name:"Device Model", description:"Model of the device", attribute:"model", type:"string", size:"100%", units:"", typeAttribute:null, value:device.model},
      {name:"Generation Capacity", description:"Generation capacity of the device", attribute:"generationCapacity", type:"number", size:"8em",  units:"KVA", typeAttribute:"generation",value:device.generationCapacity},
      {name:"Load Capacity", description:"Load capacity of the device", attribute:"loadCapacity", type:"number", units:"KVA",  size:"8em", typeAttribute:"load",value:device.loadCapacity},
      {name:"Storage Capacity", description:"Storage capacity of the device", attribute:"storageCapacity", type:"number", units:"KWh", size:"8em", typeAttribute:"storage", value:device.storage},
    ];
    console.log(device.pin.getLngLat());
    const createItem = function(prop : any){
      let value = device[prop.attribute];
  
      return (
        <IonRow key={prop.attribute}>
          <IonCol>
            <div className="connections-device-properties-headers">
            {/* address section */}
            <IonLabel className='label' position="stacked">{prop.name}</IonLabel>
            <IonLabel className='sublabel' position="stacked">{prop.description}</IonLabel>
            </div>
          </IonCol>
          
          <IonCol>
            <div className="connections-device-properties" style={{width:prop.size}}>
              <IonInput className='connections-parameter' type={prop.type} size={prop.size} value={value} onIonInput={e => prop.value = e.target.value + ''}></IonInput>
              <IonLabel className='sublabel'>&nbsp;{prop.units}</IonLabel>
            </div>
          </IonCol>
        </IonRow>
      )
    };
  
    const update = function(){
      

      console.log(properties);
      for (let i=0; i<properties.length; i++){
        let prop = properties[i];
        device[prop.attribute] = prop.value;
      }

      if (device.name === '' || device.manufacturer === '' || device.model === '') {
        presentAlert({
          header: 'Data Incomplete',
          message: 'You must enter a name, manufacturer, and model for the device',
          cssClass: 'connections-default-alert',
          buttons: [
            {
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        //alert('You need to choose one option for all type, phase and connection voltage!');
        return;
      }

      setModalOpen(false);
      modal.current?.dismiss();
    }
  
    const [presentAlert] = useIonAlert();
    const deleteDevice = function(){
      presentAlert({
        header: 'Delete Device?',
        cssClass: 'connections-default-alert',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Delete',
            role: 'confirm',
            handler: () => {
              if (props.siteType == SiteType.SinglePoint){
                let loc = device.pin.getLngLat();
                let marker = props.createMarker(loc);
                marker.addTo(props.map.current);
                props.marker.current = marker;
              }
              props.devices.delete(device.id);
              device.delete();
              setModalOpen(false);
              modal.current?.dismiss();
            },
          },
        ],
      })
    }
  
  
    const buildInterface = () => {
      let items = [];
      for (let i=0; i<properties.length; i++){
        let prop = properties[i];
        console.log(prop.typeAttribute+" : "+device.type);
        if (prop.typeAttribute == null || (prop.typeAttribute!=null && device.type[prop.typeAttribute])){
          items.push(createItem(prop));
        }
      }
      return (
        <IonGrid key={device.id} className="connections-device-properties-container">
          {items}
          <IonRow key={"submit-button"}>
          <IonCol>
            <IonButton className="connections-modal-button" onClick={update}>{device.opened ? "Update" : "Set"}</IonButton>
          </IonCol>
          </IonRow>
          <IonRow key={"delete-button"}>
          <IonCol>
            <IonButton class={device.opened ? "danger" : "ion-hidden"} className=" connections-modal-button connections-modal-delete-button" onClick={deleteDevice}>Delete</IonButton>
          </IonCol>
  
          </IonRow>
        </IonGrid>
      )
    }
  
    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
      setModalOpen(false);
      device.opened = true;
      props.clearDevice();
      console.log(ev);
      if (ev.detail.role === 'confirm') {
        
      }
    }
  
    return (
      <>
      <div>
      <IonModal isOpen={true} ref={modal} onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => {
                  setModalOpen(false);
                  modal.current?.dismiss();}
                }>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot="end">
              <IonButton onClick={update}>
                {device.opened ? "Update" : "Set"}
              </IonButton>
              </IonButtons>                  
              <IonTitle class="ion-text-center">
                <FontAwesomeIcon icon={device.type.icon}/>&nbsp;
                {device.type.name} Properties</IonTitle>
            </IonToolbar>
          </IonHeader>
         
          <IonContent className="ion-padding">
            {buildInterface()}
          </IonContent>
        </IonModal>
        </div>
      </>
    );
  };
  
  export default DeviceCharacteristics;