import { faCaretRight, faPenAlt, faCogs, faRulerTriangle, faTriangleExclamation, faBan, faCoins } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, useIonAlert } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import mapboxgl from "mapbox-gl";
import * as turf from '@turf/turf';
import { useRef } from "react";
import { SiteType } from "./DataTypes";

interface ConnectiongRoutingOptionsProps{
  ManualRouting : Function;
  AutomaticRouting : Function;
}

export const ConnectionRoutingOptions : React.FC<ConnectiongRoutingOptionsProps> = (props: any) => {
  const modal = useRef<HTMLIonModalElement>(null);
  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      
    }
  }

  function confirm(onConfirm : any) {
    modal.current?.dismiss();
    onConfirm();
  }

  const [presentAlert] = useIonAlert();

  return (
     <div>
    <div className="connections-continue-button-container"><IonButton className="connections-continue-button" id="connections-start-routing">Start Connection Routing&nbsp;<FontAwesomeIcon icon={faCaretRight}/></IonButton></div>  
      
      <IonModal ref={modal} trigger="connections-start-routing" onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
              </IonButtons>
              <IonTitle class="ion-text-center">Route to Network</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Manual Routing</IonCardTitle>
                <IonCardSubtitle>Route the cables and lines from the site to the electrical network manually using the editing tools</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
              <IonButton className="connections-modal-button" onClick={() => confirm(props.ManualRouting)}><FontAwesomeIcon icon={faPenAlt}/>&nbsp;Manually Route</IonButton>
            </IonCardContent>
          </IonCard>
          <IonCard>
              <IonCardHeader>
                <IonCardTitle>Automatic Routing</IonCardTitle>
                <IonCardSubtitle>Automaticlly route the site to the electrical network (and then optionally edit the calculated route)</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
              <IonButton className="connections-modal-button" onClick={() => confirm(props.AutomaticRouting)}><FontAwesomeIcon icon={faCogs}/>&nbsp;Automatically Route</IonButton>
            </IonCardContent>
          </IonCard>
          </IonContent>
        </IonModal>
  </div>
  )
}