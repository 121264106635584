import { faCaretRight, faHouseChimney, faBuildings, faIndustry, faPlug, faTransformerBolt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonAlert, IonButton, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonRadioGroup, IonRadio } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useContext, useRef, useState } from "react";
import { FormContext, SiteType } from "./DataTypes";
import { getCapabilities } from "../App";

interface ConnectionDetailsProps {
    siteType : SiteType | null;
    DefineSite : Function;
    SubmitForm : Function;
    address : any;

}


const ConnectionDetails: React.FC<ConnectionDetailsProps> = (props: any) => {
    const contextData = useContext(FormContext);
  
    const buttonStyles = {
      root:'connections-type-button',
      selected:'connections-type-selected',
      unselected:'connections-type-unselected'
    };
  
    const [selectedType, setSelectedType] = useState(false); // state indicating change of the map's style
    const [modalOpen, setModalOpen] = useState(props.siteType == SiteType.Area); // state indicating change of the map's style
    
    const modal = useRef<HTMLIonModalElement>(null);
    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
      setModalOpen(false);
      if (ev.detail.role === 'confirm') {
        
      }
    }
  
    const [presentAlert] = useIonAlert();
  
    const updateData = function(e : any){
      console.log("Updating");
      {/* prevent page refresh on submission */ }
      //e.preventDefault();
  
      {/* validation - only proceed if user entered all the necessary information */ }
      {/* location details are validated with 'required' input field */ }
      if (contextData.type === '' || contextData.phase === '' || contextData.voltage === '') {
        presentAlert({
          header: 'Data Incomplete',
          message: 'You must enter an address and select a Customer Type, Connection Phase(s) and Voltage',
          cssClass: 'connections-default-alert',
          buttons: [
            {
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        //alert('You need to choose one option for all type, phase and connection voltage!');
        return;
      }
  
      if (contextData.voltage == 'lv'){
        contextData.networkKind = 'lv';
      }else if (contextData.voltage == '6.6'){
        contextData.networkKind = 'secondary';
      }else if (contextData.voltage == '11'){
        contextData.networkKind = 'secondary';
      }else if (contextData.voltage == '33'){
        contextData.networkKind = 'primary';
      }
      console.log(contextData.networkKind);
  
      if (!contextData.address1) {
        contextData.address1 = props.address[0] + ''
      }
      if (!contextData.city) {
        contextData.city = props.address[1] + ''
      }
      if (!contextData.postcode) {
        contextData.postcode = props.address[2] + ''
      }
      
      contextData.set = true;
  
      props.DefineSite(false);
      props.SubmitForm();
      modal.current?.dismiss();   
    }
      
    let voltages = getCapabilities().voltages;
    console.log(voltages);
    return (
      <>
      <div className="connections-continue-button-container">
      <IonButton class={(modalOpen || props.siteType==SiteType.Area) ? 'ion-hidden' : ''} id="connections-set-connection-details"
      onClick={() => {setModalOpen(true);}}
      className="connections-continue-button" >Confirm Site Location&nbsp;<FontAwesomeIcon icon={faCaretRight}/></IonButton>
      <IonModal isOpen={modalOpen} ref={modal} onWillDismiss={(ev) => onWillDismiss(ev)}>
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={() => {setModalOpen(false); modal.current?.dismiss()}}>Cancel</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                    <IonButton id='submit' type='submit' onClick={updateData}>
                      Continue
                      <FontAwesomeIcon icon={faCaretRight}/>
                    </IonButton>
                    </IonButtons>                  
                    <IonTitle class="ion-text-center">Site Details</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                {/* <form className="connections-info-form"> */}
          <IonItem lines="none">
            <IonLabel className='label'>Address</IonLabel>
          </IonItem>
          <IonItem lines="full">
            <div className='formInputContainer'>
              <IonInput value={props.address[0]} required={true} onIonInput={(e) => { contextData.address1 = e.target.value + ''}} className='standardInput' placeholder='Address Line 1' />
              <IonInput value={contextData.address2} required={false} onIonInput={(e) => { contextData.address2 = e.target.value + '' }} className='standardInput' placeholder='Address Line 2' />
              <div className='inlineInputs'>
                <IonInput value={props.address[1]} required={true} onIonInput={(e) => { contextData.city = e.target.value + ''}} className='smallInput' placeholder='City' />
                <IonInput value={props.address[2]} required={true} onIonInput={(e) => { contextData.postcode = e.target.value + ''}} className='smallInput' placeholder='Postcode' />
              </div>
            </div>
          </IonItem>
          <IonItem lines="none">
            {/* connection type */}
            <IonLabel className='label'>Customer Type</IonLabel>
          </IonItem>
          <IonItem  lines="none">
            <div className='connections-button-container'>
              <IonRadioGroup className="connections-form-group" value={selectedType} onIonChange={e => contextData.type = e.detail.value} allowEmptySelection={true}>
              <IonItem>
                <IonRadio  value='residential' className='connections-radio'/>
                <IonLabel className='connections-radio-sublabel'>
                <span className="connections-radio-icon"><FontAwesomeIcon icon={faHouseChimney} swapOpacity/></span>
                  <strong>Residential</strong></IonLabel>
              </IonItem>
              <IonItem>
                <IonRadio value='commercial' className='connections-radio'/>
                <IonLabel className='connections-radio-sublabel'>
                <span className="connections-radio-icon"><FontAwesomeIcon icon={faBuildings} swapOpacity/></span>
                  <strong>Commercial</strong></IonLabel>
              </IonItem>
              <IonItem>
                <IonRadio value='industrial' className='connections-radio'/>
                <IonLabel className='connections-radio-sublabel'>
                <span className="connections-radio-icon"><FontAwesomeIcon icon={faIndustry} swapOpacity/></span>
                  <strong>Industrial</strong></IonLabel>
              </IonItem>
              </IonRadioGroup>
            </div>
          </IonItem>
          <IonItem>
            {/* connection details */}
            <IonLabel className='label'>Connection Phases</IonLabel>
          </IonItem>
          <IonItem>
            <div className='connections-button-container'>
            <IonRadioGroup className="connections-form-group" value={selectedType} onIonChange={e => contextData.phase = e.detail.value} allowEmptySelection={true}>
            <IonItem>
                <IonRadio  value='single-phase' className='connections-radio'/>
                <IonLabel className='connections-radio-sublabel'>
                <span className="connections-radio-icon"><FontAwesomeIcon icon={faPlug}/></span>
                  <strong>Single Phase</strong></IonLabel>
            </IonItem>
            <IonItem>
                <IonRadio  value='three-phase' className='connections-radio'/>
                <IonLabel className='connections-radio-sublabel'>
                <span className="connections-radio-icon"><FontAwesomeIcon icon={faTransformerBolt } /></span>
                  <strong>Three Phase</strong></IonLabel>
            </IonItem>
            </IonRadioGroup>
            </div>
          </IonItem>
          <IonItem>
            <IonLabel className='label'>Connection Voltage</IonLabel> <br></br>
          </IonItem>
          <IonItem>
            <div className='connections-button-container'>
            <IonRadioGroup className="connections-form-group" value={selectedType} onIonChange={e => contextData.voltage = e.detail.value} allowEmptySelection={true}>
              {
                
                voltages.map((voltage : any) => {
                  return (
                <IonItem key={voltage.value}>
                  <IonRadio value={voltage.value} className='connections-radio'/>
                    <IonLabel className='connections-radio-sublabel'>
                    &nbsp;<strong>{voltage.value}</strong>
                    <span className="connections-radio-icon">{voltage.unit}</span>
                      </IonLabel>
                </IonItem>
                  )
                })
             
              }
            </IonRadioGroup>
            </div>
            </IonItem>
            <IonItem>
            <IonButton className="connections-modal-button" onClick={updateData}>Continue&nbsp;<FontAwesomeIcon icon={faCaretRight}/></IonButton>
  
          </IonItem>
        {/* </form> */}
                </IonContent>
              </IonModal>
    </div>
    </>
    )
  
  
  };

  export default ConnectionDetails;