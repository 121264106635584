import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonContent, IonFab, IonFabButton, IonFabList, IonHeader, IonIcon, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { add } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { DeviceType } from "./DataTypes";
import { getCapabilities } from "../App";

interface PaletteProps {
    DeviceFunctionality : Function;
    deviceTypes : Array<DeviceType>
}

interface TypeProps {
    DeviceFunctionality : Function;
    deviceTypes : Array<DeviceType>;
    setDisplayDeviceMenu : React.Dispatch<React.SetStateAction<boolean>>
    address : React.MutableRefObject<string | undefined>;
}
export const DevicePalette : React.FC<PaletteProps> = (props: any) => {

    const createEntry = function(deviceType : DeviceType){
      const setDeviceType = props.DeviceFunctionality;
      
      return (
  
        <IonFabButton key={deviceType.id} className="connections-device-palette-entry" onClick={() => {setDeviceType(deviceType)}}>
          <FontAwesomeIcon icon={deviceType.getIcon()}/>
          </IonFabButton>
      )
    };
  
    const renderEntries = () => {
      let items = [];
      console.log(getCapabilities().deviceTypes);
      for (let i = 0; i < props.deviceTypes.length; i++){
        let deviceType = props.deviceTypes[i];
        if (getCapabilities().deviceTypes[deviceType.id]){
          let entry = createEntry(deviceType);
          items.push(entry);
        }
      }
      return items;
    }
  
    return (
      <div>
          <IonFab vertical="center" horizontal="end" slot="fixed" id="connections-device-palette">
            <IonFabButton>
              <IonIcon icon={add}/>
            </IonFabButton>
            <IonFabList side="start">
              {renderEntries()}
            </IonFabList>
          </IonFab>
      </div>
    )
  };

  export const DeviceTypes : React.FC<TypeProps> = (props: any) => {
  
    const modal = useRef<HTMLIonModalElement>(null);
    const [isOpen, setIsOpen] = useState(true);
  
    console.log("Loading Device Menu "+isOpen);
    const setDeviceType = function(type: DeviceType){
      console.log(type);
      modal.current?.dismiss();
      setIsOpen(false);
      props.DeviceFunctionality(type);
    };
  
    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
      modal.current?.dismiss();
      props.setDisplayDeviceMenu(false);
      setIsOpen(false);
      console.log(ev);
      if (ev.detail.role === 'confirm') {
        
      }
    }
  
    const createEntry = function(deviceType : DeviceType){
      return (
        <IonItem lines='none' key={deviceType.id}>
        <IonButton onClick={() => {setDeviceType(deviceType)}}>
        <span className="connections-device-icon"><FontAwesomeIcon icon={deviceType.getIcon()}/></span>{deviceType.getName()}
          </IonButton>
      </IonItem>
      )
    };
  
    const renderEntries = () => {
      let items = [];
      for (let i = 0; i < props.deviceTypes.length; i++){
        let deviceType = props.deviceTypes[i];
        if (getCapabilities().deviceTypes[deviceType.id]){
          let entry = createEntry(deviceType);
          items.push(entry);
        }
      }
      return items;
    }
  
    return (
      <>
      <div>
      <IonModal isOpen={isOpen} ref={modal} className="connections-device-type" onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle class="ion-text-center">Device Type</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {renderEntries()}
          </IonContent>
        </IonModal>
        </div>
        </>
        
    );
    
  }
