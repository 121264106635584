import ReactDOM from 'react-dom';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { FeatureCollection, Geometry } from "@turf/turf";
import { getMapboxDrawStyle } from "./MapUtilities";
import * as turf from '@turf/turf';
import mapboxgl from "mapbox-gl";
import { DISTANCE_VALIDATION_INCREMENT, INCLUDE_OVERHEAD_EXTRUDE, LINE_LENGTH_LIMIT, setConnectionRoute, setDrawnConnectionRoute } from "./ExploreContainer";
import { analytics } from "ionicons/icons";
import { Device, SiteType } from "./DataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRulerTriangle, faTriangleExclamation, faBan, faCoins, faUtilityPole, faRulerHorizontal, faReel, faPersonDigging } from "@fortawesome/pro-duotone-svg-icons";
import { useIonAlert, UseIonAlertResult } from '@ionic/react';
import { useEffect } from 'react';

let styledColours : any = null;
let latestRoute : any;
let latestGeometry : any;
let activeDraw : MapboxDraw | null;

const jQuery = require('jquery');

//+ Jonas Raoni Soares Silva
//@ http://jsfromhell.com/math/closest-polyline-point [rev. #1]

export const project = ( p : any, a : any, b : any ) => {
    
    var atob = { x: b.x - a.x, y: b.y - a.y };
    var atop = { x: p.x - a.x, y: p.y - a.y };
    var len = atob.x * atob.x + atob.y * atob.y;
    var dot = atop.x * atob.x + atop.y * atob.y;
    var t = Math.min( 1, Math.max( 0, dot / len ) );

    dot = ( b.x - a.x ) * ( p.y - a.y ) - ( b.y - a.y ) * ( p.x - a.x );
    
    return {
        point: {
            x: a.x + atob.x * t,
            y: a.y + atob.y * t
        },
        left: dot < 1,
        dot: dot,
        t: t
    };
}

const getLineType = function (props: any, distance: number) : string{
    let type = "underground";
    for (let l = 0; l < props.length; l++) {
        let c = props[l];
        if (c.sourceLayer === "road") {
            if (c.properties.class == 'track'){
                type = "overhead";
            }
        }else if (c.sourceLayer === "landuse" || c.sourceLayer === "landcover") {
            let cls = c.properties.class;
            if (cls == "agriculture" ||
            cls == "glacier" ||
            cls == "grass" ||
            cls == "rock" ||
            cls == "scrub" ||
            cls == "wood")
                type = "overhead";
        }
    }
    return type;
}


const getClosestPointOnSegment = function(s1 : Array<number>, s2 :Array<number>, p : Array<number>) : Array<number>{
    let xDelta : number = s2[0] - s1[0];
    let yDelta : number = s2[1] - s1[1];

    if ((xDelta == 0) && (yDelta == 0)){
        return [];
    }

    let u : number = ((p[0] - s1[0]) * xDelta + (p[1] - s1[1]) * yDelta) / (xDelta * xDelta + yDelta * yDelta);

    let closestPoint : Array<number>;
    if (u < 0){
        closestPoint = s1;
    }else if (u > 1){
        closestPoint = s2;
    }else{
        closestPoint = [s1[0] + u * xDelta, s1[1] + u*yDelta]; 
    }

    let locationP = new mapboxgl.LngLat(p[0], p[1]);
    let locationC = new mapboxgl.LngLat(closestPoint[0], closestPoint[1]);

    return [closestPoint[0], closestPoint[1], locationP.distanceTo(locationC)];
}


export const findNearestLinePoint = function(map: React.MutableRefObject<any>, start: mapboxgl.LngLat, voltage: any) {
    let location: any;
    let finish = new mapboxgl.LngLat(0, 0);
    // find the closest connection point
    let distance : number | undefined = undefined;
    let p = start.toArray();
    map.current.queryRenderedFeatures().forEach((e: any) => {
      if (e.toJSON().layer.id === 'network-lines') {
        let coords = e.toJSON().geometry.coordinates;
        for (let i = 1; i < coords.length; i++) {
          // filter out the results
          let f = coords[i-1];
          let t = coords[i];
          if (!Array.isArray(f[0]) && !Array.isArray(t[0])) {
            let cp = getClosestPointOnSegment(f,t,p);
            let d = cp[2];
            if (distance == undefined || d < distance) {
                location = new mapboxgl.LngLat(cp[0], cp[1]);
                finish = location;
                distance = d;
            }
          }
        }
      }
    });
    console.log(finish);
    return finish;
  }

const verifyConnectionSegment = function (props: any, type : string, distance: number) : string{
    let terrain = { hasRoad: false, hasWater: false, hasBuilding: false, hasLandcover: false, hasLanduse: false }; // assume no particular terrain type
    // go over the querried features and check terrain type - record it
    for (let l = 0; l < props.length; l++) {
        let currentLayer = props[l];

        if (currentLayer.sourceLayer === "road") terrain.hasRoad = true;
        else if (currentLayer.sourceLayer === "water") terrain.hasWater = true;
        else if (currentLayer.sourceLayer === "waterway") terrain.hasWater = true;
        else if (currentLayer.sourceLayer === "natural_label" && currentLayer.layer.id === 'waterway-label') terrain.hasWater = true;
        else if (currentLayer.sourceLayer === "building") terrain.hasBuilding = true;
        else if (currentLayer.sourceLayer === "landcover") terrain.hasLandcover = true;
        else if (currentLayer.sourceLayer === "landuse") terrain.hasLanduse = true;
        else continue;
    }
    // return the colour a particular segment should be coloured(rules can be changed depending on what behaviour is desired)
    if (distance <= 30 * DISTANCE_VALIDATION_INCREMENT) return 'valid';
    else if (terrain.hasRoad && terrain.hasWater) return 'warning';
    else if (!terrain.hasRoad && terrain.hasWater && type!="overhead") return 'invalid';
    else if (terrain.hasRoad && !terrain.hasWater) return 'valid';
    else if (terrain.hasBuilding) return 'invalid';
    else if (terrain.hasLandcover) return 'valid';
    else if (terrain.hasLanduse) return 'valid'; // if set to yellow, the app slows down significantly
    else return 'valid'
}

const validateRoute = function (
    map: React.MutableRefObject<any>,
    geometry: any): any {

    let features: any = [];
    let coords = geometry.coordinates;
    let currentFeature : any = {};
    let lastStatus = null;
    let lastAngle : number | null = null;
    let lastType : string | null = null;
    for (let i = 0; i < turf.length(turf.lineString(coords), { units: 'kilometers' }); i = i + DISTANCE_VALIDATION_INCREMENT) {
        // the diagonal of each bbox(area of the map to query) is going to be: every next line segment of length 10 meters
        let start = turf.along(turf.lineString(coords), i, { units: 'kilometers' });
        let end = turf.along(turf.lineString(coords), i + DISTANCE_VALIDATION_INCREMENT, { units: 'kilometers' })

        let bbox = turf.bbox(turf.featureCollection([start, end]))
        // convert bbox to pixel coordinates - not sure which one to choose lol, not great with coordinates
        let swPoint = map.current.project(new mapboxgl.LngLat(Math.min(bbox[0], bbox[2]), Math.min(bbox[1], bbox[3])));
        let nePoint = map.current.project(new mapboxgl.LngLat(Math.max(bbox[0], bbox[2]), Math.max(bbox[1], bbox[3])));

        // query the layers within the bounding box
        let allSourceData = map.current.queryRenderedFeatures([[swPoint.x, swPoint.y], [nePoint.x, nePoint.y]]);

        // update the validation variable, update the connection layer, and move to the next point
        let type = getLineType(allSourceData, i);
        let valid = verifyConnectionSegment(allSourceData, type, i);
        let angle = Math.atan2(bbox[3] - bbox[1], bbox[2] - bbox[0]);
        let angleDiff = 0;
        if (lastAngle!=null){
            angleDiff = Math.abs(lastAngle - angle);
        }
        if (lastStatus == null || valid != lastStatus || angleDiff > 0.087 || lastType != type){ // roughly 5 degrees
            // First one
            if (lastStatus!=null){
                features.push(currentFeature);
            }
            currentFeature = {
                'type': 'Feature',
                'properties': {
                    'status': valid,
                    'type': type
                },
                'geometry': {
                    'type': 'LineString',
                    'coordinates': [start.geometry.coordinates, end.geometry.coordinates]
                }
            };
        }else if (valid == lastStatus){
            currentFeature.geometry.coordinates.push(end.geometry.coordinates);
        }
        lastStatus = valid;
        lastAngle = angle;
        lastType = type;
    }
    if (currentFeature!=null){
        features.push(currentFeature);
    }
    return features;
}

const findNearestAreaPoint = function(areas : React.MutableRefObject<any>, point : mapboxgl.LngLat){
    let start = new mapboxgl.LngLat(0, 0);
    let distance = -1;
    for (let a=0; a<areas.current.features.length; a++){
        let areaCoords = areas.current.features[a].geometry.coordinates[0];
        for (let i = 0; i < areaCoords.length - 1; i++) {
            // take the midpoint of each edge and check how far away it is from the first point of the connection outside of the area
            let mid = new mapboxgl.LngLat((areaCoords[i][0] + areaCoords[i + 1][0]) / 2, (areaCoords[i][1] + areaCoords[i + 1][1]) / 2);
            
            let n = project({x:point.lng, y:point.lat},{x:areaCoords[i][0], y:areaCoords[i][1]}, {x:areaCoords[i+1][0], y:areaCoords[i+1][1]});
            let p = new mapboxgl.LngLat(n.point.x, n.point.y);
            let d = p.distanceTo(point);
            //console.log(d+" to "+mid);
            if (distance == -1 || d < distance) {
                start.lng = p.lng;
                start.lat = p.lat;
                distance = d;
            }
        }
    }
    return start;
}

export const confirmConnection = (
    map: React.MutableRefObject<any>,
) => {
    //console.log(latestRoute);
    if (activeDraw!=null){
        activeDraw.changeMode("simple_select");
    }
    
}

export const startManuallyConnectingDevice = (
    map: React.MutableRefObject<any>,
    existing : any,
    siteType : SiteType | null,
    device : Device | null,
    areas : React.MutableRefObject<any>,
    confirmConnection : Function) => {

    let mode = 'draw_line_string';
    if (existing!=null)
        mode = "simple_select";

    const mDraw = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        // select control buttons for the polygon drawing feature
        controls: {
            //polygon: true,
            line_string: true,
            trash: true

        },
        styles: getMapboxDrawStyle(),
        defaultMode: mode,
    });
    //draw.current = mDraw;
    activeDraw = mDraw;
    map.current.addControl(mDraw);
    if (existing != null) {
        mDraw.set(existing);
    }

    if (styledColours == null){
        styledColours = {};
        styledColours.succces = "#3EC21D";//window.getComputedStyle(document.body).getPropertyValue('--ion-color-success');
        styledColours.danger= window.getComputedStyle(document.body).getPropertyValue('--ion-color-danger');
        styledColours.warning= window.getComputedStyle(document.body).getPropertyValue('--ion-color-warning'); 
    }

    map.current.addSource('validated-drawing-route',
        { type: "geojson", data: {} });

    map.current.addSource('validated-drawing-overhead-route',
        { type: "geojson", data: {} });

    if (INCLUDE_OVERHEAD_EXTRUDE){
        map.current.addLayer({
            'id': 'interactive-draw-lines',
            'type': 'fill-extrusion',
            'source': 'validated-drawing-overhead-route',
            'paint': {
                'fill-extrusion-opacity': 0.9,
                'fill-extrusion-height': 5,
                'fill-extrusion-color':{
                    'property':'status',
                    'type':'categorical',
                    'stops':[
                        ['valid', styledColours.succces],
                        ['warning', styledColours.warning],
                        ['invalid', styledColours.danger],
                    ]
                }
            }
        });
    }

    map.current.addLayer({
        'id': 'interactive-draw-overhead',
        'type': 'line',
        'source': 'validated-drawing-route',
        'paint': {
            'line-width': 6,
            'line-color':{
                'property':'status',
                'type':'categorical',
                'stops':[
                    ['valid', styledColours.succces],
                    ['warning', styledColours.warning],
                    ['invalid', styledColours.danger],
                ]
            }
        }
    });

    let update = function (startPoint : mapboxgl.LngLat | null) {
        if (mDraw.getAll().features.length > 0) {
            let geom : any = mDraw.getAll().features[0].geometry;
            if (startPoint!=null){
                geom.coordinates.unshift([startPoint.lng, startPoint.lat]);
                let last = geom.coordinates[geom.coordinates.length - 1];
                let finish = findNearestLinePoint(map, new mapboxgl.LngLat(last[0], last[1]), 'none');
                if (finish){
                    geom.coordinates.push([finish.lng, finish.lat]);
                }

            }
            let features = validateRoute(map, geom);;
            //let extrudeFeatures : any = []
            //let connectionLength = 0;
            // for (let f=0; f<features.length; f++){
            //     connectionLength += turf.length(features[f], { units: 'kilometers' });
            //     if(features[f].properties.type == "overhead"){
            //         let polygon = turf.buffer(features[f], 1, {units:"meters"});
            //         polygon.properties = features[f].properties;
            //         extrudeFeatures.push(polygon);
            //     }

            // }
            latestRoute = {
                'type': 'FeatureCollection',
                'features': features
            }

            latestGeometry = mDraw.getAll();

            // let overhead = {
            //     'type': 'FeatureCollection',
            //     'features': extrudeFeatures
            // }

            //console.log(overhead);
            
            map.current.getSource('validated-drawing-route').setData(
                latestRoute    
            );

            // map.current.getSource('validated-drawing-overhead-route').setData(
            //     overhead    
            // );

            setConnectionRoute(features);
            setDrawnConnectionRoute(latestGeometry.features);

            let summary = getSummary(features);
             let routeInfo = jQuery("#connections-routing-info-details");
             ReactDOM.unmountComponentAtNode(routeInfo[0]);
             ReactDOM.render(
                        <>
                        <div>
                            {connectionSummary(summary)}
                        </div>
                    </>
                , routeInfo[0]
            );
        }
    };

    let startPoint : mapboxgl.LngLat | null;
    if (siteType == SiteType.SinglePoint && device!=undefined){
        startPoint = device.pin.getLngLat();
    }


    map.current.on('draw', (event: any) => {
        console.log(event);
    });

    map.current.on('draw.create', () => {
        update(startPoint);
        confirmConnection(true);
    });

    map.current.on('draw.update', (event: any) => {
        console.log(event);
        update(startPoint);
    });
    let last: number = 0;
    let timeOut: any;
    let lastCount = 0;
    let firstPoint = true;
    let connecting = false;

    map.current.on('draw.render', (event: any) => {
        if (mDraw.getAll().features.length==0)
            return;
        let fc = mDraw.getAll()
        let geom: any = fc.features[0].geometry;
        if (firstPoint && geom.coordinates != null && geom.coordinates.length == 1 && siteType == SiteType.Area) {
            let c = geom.coordinates[0];
            startPoint = findNearestAreaPoint(areas, new mapboxgl.LngLat(c[0], c[1]));           
        }else if (firstPoint && geom.coordinates != null && geom.coordinates.length >1){
            firstPoint = false;
        }
        if (geom.coordinates != null && geom.coordinates.length>0){
            update(startPoint);
        }
        /*
        if (geom.coordinates != null && geom.coordinates.length!=lastCount && geom.coordinates.length>1){
            update(startPoint);
            lastCount = geom.coordinates.length;
        }else if (geom.coordinates != null && geom.coordinates.length==1){
            update(startPoint);
        }
        */
        // //console.log(mDraw.getAll());
        // let now = new Date().getTime();
        // if (timeOut!=null)
        //     clearTimeout(timeOut);

        // if (now - last < 250)
        //     update();
        // else{
        //     timeOut = window.setTimeout(function(){update()}, 250);
        // }
    });

    
    map.current.on('click', (ev : any) => {
        console.log(mDraw.getMode());

        if (mDraw.getMode() == "draw_line_string"){
            const features = map.current.queryRenderedFeatures([ev.point.x, ev.point.y]);
            console.log(features);
            for (let i=0; i<features.length; i++){
                let f = features[i];
                if (f.layer.id == "network-lines" && f.properties.class == "ACLineSegment"){
                    console.log(ev);
                    console.log("Connecting to "+f.properties.name + " at "+ev.point);
                    let type = "Cable"
                    if (f.properties.name.startsWith('Line')){
                        type = "Overhead Line"
                    }

                    confirmConnection(true);
                }

                }
            }
        });
    map.current.on('draw.delete', () => {
        update(startPoint);
    });
}

const calculateCost = (route:any, cableLength : number, overheadLength : number, poles : number) => {
    let cost = 0;
    cost += cableLength * 23000;
    cost += overheadLength * 12000;
    cost += poles * 2500;
    // for (let f=0; f<route.length; f++){
    //     let connectionLength = turf.length(route[f], { units: 'kilometers' });
    //     cost += connectionLength * 12000;
    // }
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
    return formatter.format(cost);//cost.toFixed(2);

}

const connectionSummary = (props:any) => {
    return (
      <>
        <div>  
            <div className="connections-route-entry"><FontAwesomeIcon icon={faPersonDigging}/>&nbsp;Underground: <strong>{props.cableLength}</strong> km</div>
            <div className="connections-route-entry"><FontAwesomeIcon icon={faReel}/>&nbsp;Overhead: <strong>{props.overheadLength}</strong> km</div>
            <div className="connections-route-entry"><FontAwesomeIcon icon={faUtilityPole}/>&nbsp;Poles: <strong>{props.poles}</strong></div>
            <div className="connections-route-entry"><FontAwesomeIcon icon={faTriangleExclamation}/>&nbsp;Warnings: <strong>{props.warnings}</strong></div>
            <div className="connections-route-entry"><FontAwesomeIcon icon={faBan}/>&nbsp;Errors: <strong>{props.errors}</strong></div>
            <div className="connections-route-entry"><FontAwesomeIcon icon={faCoins}/>&nbsp;Est. Cost: <strong>{props.cost}</strong></div>
          {/* <div>
            <a download="connection.txt" href={props.file} id='downloadLink'>
              <button id="downloadButton">Save GeoJson</button>
            </a>
          </div> */}
        </div>
      </>
    );
  }

  const getSummary = (features: any) => {

    let cableLength = 0;
    let overheadLength = 0;
    let warnings = 0;
    let errors = 0;
    let poles = 0;
    for (let f=0; f<features.length; f++){
        let d =turf.length(features[f], { units: 'kilometers' });;
        if (features[f].properties.type == "underground"){ cableLength += d;
        }else{
            overheadLength += d
            if (poles == 0) poles = 2;
            else if (d>LINE_LENGTH_LIMIT){
                let segments = d/LINE_LENGTH_LIMIT;
                console.log(segments+" for "+d+" vs "+LINE_LENGTH_LIMIT);
                segments = Math.floor(segments)+1;
                console.log(segments);
                poles += segments;
            }else{
                poles ++;
            }
        }
        if (features[f].properties.status == "invalid") errors++;
        else if (features[f].properties.status == "warning") warnings++;
    }

    let summary = {
        cableLength: cableLength.toFixed(2),
        overheadLength: overheadLength.toFixed(2),
        poles : poles,
        cost: calculateCost(features, cableLength, overheadLength, poles),
        warnings: warnings,
        errors: errors,
    }
    return summary;
  }