import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './components/Login';
import {SERVER_URL, getKey, getUser, setAccess} from './components/ExploreContainer';
import Authenticate from './components/Authenticate';

setupIonicReact({
  rippleEffect: true,
  mode: 'md'
});

let isAuth:boolean = false;
let capabilties : any = {};

export const setAuth = (authed: boolean) => {
  isAuth = authed;
  if (!isAuth){
    ACCESS_CREDENTIALS.token = null;
    ACCESS_CREDENTIALS.basicAuthentication = "" ;
  }
}

export const ACCESS_CREDENTIALS = { token: null, basicAuthentication: "", hash:"" };

export const getCapabilities: () => any = () => {
  return capabilties;
}

const updateServerCapabilities = async () => {
  const response = await fetch(SERVER_URL+"/cimphony/concert/connections/capabilities"+ "?username=" + getUser() + "&key=" + getKey());
  const cap = await response.json();
  console.log(cap);
  capabilties = cap;
  return cap;
}

const App: React.FC = () => {
  if (window.location.hostname!="localhost" && window.location.protocol == "http:"){
    let replace = window.location.href.toString().replace("http:", "https:");;
    window.location.href = replace;
  }
  
  let hash: string = window.location.hash;
  console.log(ACCESS_CREDENTIALS.token);
  if (ACCESS_CREDENTIALS.token == null && hash != null && hash.length > 1) {
    console.log(hash);
    try {
      if (hash.startsWith("#")) {
        hash = hash.substring(1);
      }
      let decoded = atob(hash);
      let token = JSON.parse(decoded);
      console.log(decoded);
      console.log(token);
      ACCESS_CREDENTIALS.token = token;
      ACCESS_CREDENTIALS.basicAuthentication = "Basic " + btoa(token.sub + ":" + token.key);
      ACCESS_CREDENTIALS.hash = hash;

      setAccess(token.sub, token.key);

      console.log(ACCESS_CREDENTIALS);

      updateServerCapabilities();
      window.location.hash = "";
      //This removes the history
      // window.history.pushState("", document.title, window.location.pathname
      //   + window.location.search)
    } catch (e) {
      console.log(e);
    }
  }else if (ACCESS_CREDENTIALS.token == null){
    setAuth(false);
  }
  console.log(ACCESS_CREDENTIALS.token);

  return (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home" render={(props) => {
          return !isAuth ? <Authenticate></Authenticate> : <Home/>}
        }/>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/authenticate" component={Authenticate} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
    )
};

export default App;
