import { useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { PopupOptions } from '!mapbox-gl';
import { IonAlert, IonButton, IonContent, IonHeader, IonItem, IonLabel, IonLoading, IonModal, IonTitle, IonToolbar, NavContext, useIonToast } from '@ionic/react';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import ReactDOM from 'react-dom';
import uuid from 'react-uuid';
import './ExploreContainer.css';

import { IconDefinition, faBuildings, faCarBattery, faCaretRight, faChargingStation, faCheck, faCheckSquare, faCloudUpload, faDownload, faEarthAmericas, faFileDownload, faFireAlt, faGasPump, faHouseBlank, faHouseChimney, faIndustry, faMapMarked, faPlug, faPlugCircleBolt, faRightFromBracket, faRoute, faSatellite, faSensorFire, faSolarPanel, faTint, faTransformerBolt, faWindTurbine } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { informationCircleOutline, pin, warning } from 'ionicons/icons';
import { ACCESS_CREDENTIALS, getCapabilities, setAuth } from '../App';
import ConnectionDetails from './ConnectionDetails';
import { ConnectionRoutingOptions } from './ConnectionRouting';
import { confirmConnection, findNearestLinePoint, project, startManuallyConnectingDevice } from './Connections';
import { Device, DeviceType, formData, getVoltageStops, SiteType } from './DataTypes';
import DeviceCharacteristics from './DeviceCharacteristics';
import { DevicePalette, DeviceTypes } from './DevicePalette';
import { getMapboxDrawStyle } from './MapUtilities';
import StartConnection from './SiteCharacteristics';

interface ContainerProps { }

const jQuery = require('jquery');

// context('static' variables) for the app - storing information submitted through the form

const local = window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1";

export let SERVER_URL = "https://connections-concert.derms.io";
// let getUser() = "connections@opengrid.com";
// let getKey() = "d386a65a5ce7614900832c7dc4fc7c382da7b7e115c4f7040870024fc19142f6";
let INITIAL_START: mapboxgl.LngLat = new mapboxgl.LngLat(-73.963, 40.786);
{
  console.log("Dev:" + local);
  if (local) {
    SERVER_URL = "http://localhost:8080";
    // getUser() = "admin@opengrid.com";
    // getKey() = "5fcead617279f198527bb591d74fe2aed560a4ef297ee651e71dcdabdf10c24c";
    INITIAL_START = new mapboxgl.LngLat(-73.963, 40.786);
  }

}

const DEFAULT_STYLE = 'mapbox://styles/opengrid/cl88wbtg4004r14qlc4k26g8v';
const SATELLITE_STYLE = 'mapbox://styles/opengrid/cl7kj5drt003316kjv04mjhpa';

const CLICK_DISTANCE = 20;

export const LINE_LENGTH_LIMIT = 0.1; // no idea
export const INCLUDE_OVERHEAD_EXTRUDE = false;

const INITIAL_ZOOM = 12;
const INITIAL_PITCH = 30;

// stacks for storing all the marker, area and device pin objects together with their addresses(so they can be all accessed and removed with undo)
// const mapItems = {
//   names: Array<string>(),
//   pins: Array<any>(),
//   kinds: Array<number>(),
//   properties: Array<Object>(),
//   type : Array<string>,
//   capacities: Array<string>() }; // kinds: pin:0, area: 1, device: 2

// icons and their types

const devices = new Map<string, Device>();

const deviceTypes = Array<DeviceType>(
  new DeviceType("battery", "Battery", faCarBattery, true, true, true),
  new DeviceType("chp", "Combined Heat Power", faSensorFire, false, false, true),
  new DeviceType("diesel", "Diesel Generator", faTint, false, false, true),
  new DeviceType("ev", "EV Charging", faChargingStation, false, true, true),
  new DeviceType("solar", "Solar", faSolarPanel, false, false, true),
  new DeviceType("substation", "Substation", faHouseBlank, false, true, false),
  new DeviceType("wind", "Wind", faWindTurbine, false, false, true),
);

let siteType: SiteType | null = null;
let autoConnect = false; // user's choice whether to connect automatically or not
let newRoute: any; // stores connection route(array of coordinates)
let connectionSource: any; // stores the connection source data - used to rebuild the connection when style changes
let invalid: any; // stores info about invalid segments of the connection
let address: any; // used to transfer the address from geolocator to the form
let pos = 0; // indicates position of the coordinates of the connection line in the mapboxdraw object(different for single vs. multiple devices)
let selectedDevice: Device | null = null;
let inRouting = false;

let connectionRoute: any = null;
let drawnConnectionRoute: any = null;
let currentNetwork: String | undefined = undefined;
let currentArea: any = undefined;
let USER: string = "";
let KEY: string = "";

export const setAccess = (username: string | null, key: string | null) => {
  if (username!=null)
    USER = username;
  else
    USER = "";
  
  if (key != null)
    KEY = key;
  else
    KEY = "";
  if (USER != "" && KEY != "") {
    setAuth(true);
  } else {
    setAuth(false);
  }
}

export const getUser: () => string = () => {
  return USER;
}

export const getKey: () => string = () => {
  return KEY;
}

export const setConnectionRoute = (route: any) => {
  connectionRoute = route;
}

export const getConnectionRoute: any = () => {
  return connectionRoute;
}

export const setDrawnConnectionRoute = (route: any) => {
  drawnConnectionRoute = route;
}

export const getDrawnConnectionRoute: any = () => {
  return drawnConnectionRoute;
}

export const getConnectionDetailsJSON: any = (areas: React.MutableRefObject<any>) => {
  let details: any = { route: getConnectionRoute() };
  let drawnRoute = getDrawnConnectionRoute();
  if (drawnRoute != null) {
    details.drawnRoute = drawnRoute;
  }
  details.devices = {};
  devices.forEach((value, key) => { details.devices[key] = value.toJSON() });
  details.form = formData;
  if (currentArea != null && areas.current != null)
    details.areas = areas.current;
  details.siteType = siteType;
  details.network = currentNetwork;

  return details;
}

export const DISTANCE_VALIDATION_INCREMENT = 0.001;

const ExploreContainer: React.FC<ContainerProps> = () => {

  const { navigate } = useContext(NavContext);

  const [satelliteStyle, setSatelliteStyle] = useState(false); // state indicating change of the map's style

  // Steps are:
  // 1. Define the site either as a point or an area
  // 2. Enter the details about the location (address, voltage, type)
  // 3. Add one (for point) or more (for area) devices with their parameters
  // 4. Route the site to the grid (including editing)
  // 5. Submit

  const [displayStartConnection, setDisplayStartConnection] = useState(true);
  const [defineSite, setDefineSite] = useState(false);
  const [defineArea, setDefineArea] = useState(false);
  const [displayDevicePalette, setDisplayDevicePalette] = useState(false);
  //const [displaySiteDetails, setDisplaySiteDetails] = useState(false);   // result of the location choice
  const [displayDeviceMenu, setDisplayDeviceMenu] = useState(false); // result of the device choice
  const [startConnection, setStartConnection] = useState(false); // indicates whether connection is being drawn or not - to display edit and finish buttons
  const [routeConnection, setRouteConnection] = useState(false);
  const [displayDeviceCharacteristics, setDisplayDeviceCharacteristics] = useState(false);
  const [displayAddressInfo, setDisplayAddressInfo] = useState(false);
  const [showConnectionInfo, setShowConnectionInfo] = useState(false);
  const [showRoutingInfo, setShowRoutingInfo] = useState(false);
  const [showConfirmConnection, setShowConfirmConnection] = useState(false);
  const [showRoutingComplete, setShowRoutingComplete] = useState(false);
  const [showFinishOptions, setShowFinishOptions] = useState(false);
  const [showDetailsComplete, setShowDetailsComplete] = useState(false);
  
  const [canFinishDeviceEditing, setCanFinishDeviceEditing] = useState(false);

  const colourPri = window.getComputedStyle(document.body).getPropertyValue('--ion-color-primary');
  const colourSec = window.getComputedStyle(document.body).getPropertyValue('--ion-color-secondary');
  const colourTer = window.getComputedStyle(document.body).getPropertyValue('--ion-color-tertiary');
  const colourSuccces = window.getComputedStyle(document.body).getPropertyValue('--ion-color-success');
  const colourDanger = window.getComputedStyle(document.body).getPropertyValue('--ion-color-danger');
  const colourWarning = window.getComputedStyle(document.body).getPropertyValue('--ion-color-warning');

  const map = useRef<any>(); // map object
  const marker = useRef<mapboxgl.Marker>(); // marker object to create a pin for the location
  // mapboxdraw and polygon objects - used for drawing the area on the map
  const draw = useRef<any>();
  const poly = useRef<any>();
  const areas = useRef<any>();
  const selectedAddress = useRef<string>();
  const geoLocatedNetwork = useRef<string>();

  let mapboxPopup = new mapboxgl.Popup({ closeOnClick: false, keepInView: true } as PopupOptions); // popup object for popup display on the map

  // querrying the terrain type along the connection every *increment* kilometers

  let connection: any = new Array<any>(); // connection between user's location and a chosen point on the network(array of 2 LngLat's)
  let lastViewed: any = new Array<any>(); // last point on the network that was under the mouse pointer
  let chosenPoint: any = new Array<any>(); // single point in the connection (either the device or point on the network)

  let setArea = false;

  const [present, dismiss] = useIonToast();

  let activeToast: HTMLIonToastElement;
  const toast = function (message: string, icon?: string, duration?: number | undefined) {
    if (duration == undefined) duration = 2000;
    if (icon == undefined) icon = informationCircleOutline;

    present({
      buttons: [{ text: 'hide', handler: () => dismiss() }],
      color: 'secondary',
      message: message,
      duration: duration,
      position: 'bottom',
      icon: icon
    });

  }

  useEffect(() => {
    // set up the map
    mapboxgl.accessToken = 'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2w4bXZtbHU5MGIxODNub2EwZnQxcXQyZSJ9.JJNSGbzeMOSgGX7jxV3dEA';//'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2lqN2RjMzg0MDA1ZHZ5bTM4bHVqbXg3aiJ9.Mo91c2hNTO2OL-RhCH020Q';
    if (map.current !== undefined && map.current !== null) {
      console.log("foo")
    } else {
      map.current = new mapboxgl.Map({
        container: 'container', // container ID

        style: DEFAULT_STYLE, //'mapbox://styles/opengrid/cl7kj5drt003316kjv04mjhpa' - alternative style
        zoom: INITIAL_ZOOM,
        center: INITIAL_START, // now starts at Dundee starting center over Oxford ([-1.2735, 51.7503])
        pitch: INITIAL_PITCH,
      });

      // create a search bar for addresses
      const searchBar = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: map.current,
        marker: true,
        placeholder: 'Search for your location',
        bbox: [-9, 50, 2, 61],
        proximity: {
          longitude: -1.27,
          latitude: 51.75
        },
        reverseGeocode: true  // for 'coordinates -> address' mappings
      });
      map.current.addControl(searchBar);
      map.current.addControl(new mapboxgl.NavigationControl());


      map.current.on('style.load', () => {
        map.current.resize();
        // modify the tool bar - add logo and remove original elements
        //document.getElementsByClassName("md title-default")[0].innerHTML = '<img id="logo" src="https://www.sse.com/media/ovsj4shv/ssen_1000x500.jpg?anchor=center&mode=crop&width=790&height=527&rnd=132310037926970000"></img>'; //sse logo - can easily change
        // add source
        addNetworkSource("primary,secondary,lv", getMapPoint());

        // add sky styling to be seen with higher pitch
        map.current.setFog({
          'horizon-blend': 0.3,
          'color': '#a2a2b3',
        });

        map.current.on('click', (ev: any) => {
          //const features = map.current.queryRenderedFeatures([ev.point.x, ev.point.y]);
          //console.log(features);
        });

      });



      map.current.on('load', () => {
        // display info about a cable on hover(only if connection hasn't been drawned yet)
        map.current.on('mouseenter', 'network-lines-over', (e: any) => {
          //if (!map.current.getLayer('connection-lines'))
          handleOverlay(e);
        });

        // close the popup when pointer moves away from the cable
        map.current.on('mouseleave', 'network-lines-over', () => {
          map.current.getCanvas().style.cursor = '';
          if (!map.current.getLayer('connection-lines')) mapboxPopup.remove();
        });


        // display info about the connection on hover
        map.current.on('mouseenter', 'connection-lines', (e: any) => {
          if (map.current.getLayer('connection-lines')) {
            let coordinates = e.features[0].geometry.coordinates[0];
            invalid.forEach((invalid: any) => {
              // check if hovering over the right segment
              if ((coordinates[0] <= invalid[0][0] && coordinates[0] >= invalid[2][0]) || (coordinates[0] >= invalid[0][0] && coordinates[0] <= invalid[2][0])) {
                if ((coordinates[1] <= invalid[0][1] && coordinates[1] >= invalid[2][1]) || (coordinates[1] >= invalid[0][1] && coordinates[1] <= invalid[2][1])) {
                  let sourceData = map.current.queryRenderedFeatures([e.point.x, e.point.y]);
                  let reason = "there's no allowed route here.";
                  map.current.getCanvas().style.cursor = 'pointer';

                  // go over all the features under the pointer and change the message if necessary
                  for (let i = 0; i < sourceData.length; i++) {
                    if (sourceData[i].sourceLayer) {
                      if (sourceData[i].sourceLayer === 'building') {
                        reason = "it's crossing a building."
                        break;
                      }
                      else if (sourceData[i].sourceLayer === 'structure') {
                        reason = "it's crossing the bridge - close proximity to water.";
                        break;
                      }
                      else if (sourceData[i].sourceLayer === 'water') {
                        reason = "it's crossing the water.";
                        break;
                      }
                    }
                  }
                  let popupInvalid = document.createElement('div');
                  ReactDOM.render(
                    <>
                      <PopupInvalidConnection colour={invalid[1]} reason={reason} />
                    </>
                    , popupInvalid);
                  mapboxPopup.setLngLat(coordinates).setDOMContent(popupInvalid).addTo(map.current);
                }
              }
            })
          }
        });


        // close the popup when pointer moves away from the connection
        map.current.on('mouseleave', 'connection-lines', () => {
          map.current.getCanvas().style.cursor = '';
          if (map.current.getLayer('connection-lines')) mapboxPopup.remove()
        });

      });


      map.current.on('click', (event: any) => {
        // when click onto the device icon display a pop up to enter 
        {
          console.log(startConnection + " " + routeConnection + " " + inRouting);
          if (inRouting) {
            return;
          }

          let closestDevice = findClosest(event.lngLat);
          // if clicked close enough to the pin (within 10m), display the popup to enter the capacity
          if (closestDevice != null && (event.lngLat).distanceTo(closestDevice.pin.getLngLat()) < CLICK_DISTANCE) {
            // display a popup
            selectedDevice = closestDevice;
            if (selectedDevice != null) {
              console.log(selectedDevice);
              setDisplayDeviceCharacteristics(true);
            }

            // ReactDOM.hydrate(
            //   <>
            //     <PopupCapacityField device={getDeviceForMarker(closest)}/>
            //   </>
            //   , popupCapacity);
            //   document.body.appendChild(popupCapacity);
            //   console.log(popupCapacity);
            //mapboxPopup.setLngLat(closest.getLngLat()).setDOMContent(popupCapacity).addTo(map.current);
          } else if (formData.set &&
            closestDevice == undefined &&
            marker.current != undefined &&
            siteType == SiteType.SinglePoint &&
            (event.lngLat).distanceTo(marker.current.getLngLat()) < CLICK_DISTANCE
          ) {
            console.log("Updating to show Device Menu");
            setDisplayDeviceMenu(true);
            //setDefineSite(true);
            // const rootElement = document.getElementById("connections-page");
            // const overlay = document.createElement("div");
            // rootElement?.appendChild(overlay);
            // ReactDOM.hydrate(
            //   <PopupMenu DeviceFunctionality={DeviceFunctionality} />
            // , overlay);
          }
        }
        // when click on the pin, display a pop up to confirm if that's the right address
        // else if (mapItems.kinds[mapItems.kinds.length - 1] === 0) {
        //   let closest = findClosest(event, 0).getLngLat();

        //   // if a new pin created, add its address to the stack
        //   if (mapItems.pins.length > mapItems.names.length) mapItems.names.push(await locationName(closest, 0));

        //   // if clicked close enouch to the pin (within 50m), display the popup
        //   if ((event.lngLat).distanceTo(closest) < 50) renderLocationPopup(closest, 0);
        // }
      });


      // // when (double) click onto the area, display a pop up to confirm if that's the right address
      // map.current.once('dblclick', async (event: any) => {
      //   // location determined using 'click' coordinates (area might be too big to select a single uniform address)
      //   let clicked = event.lngLat;

      //   // if a new area drawn, add its address to the stack
      //   if (mapItems.pins.length > mapItems.names.length) mapItems.names.push(await locationName(clicked, 1));

      //   // if clicked within the area and no pins on the map yet, display the popup
      //   if (poly && (new mapboxgl.LngLatBounds(poly.current.bbox)).contains(clicked) && mapItems.pins.length === 1) renderLocationPopup(clicked, 1);
      // });

    }
  }, []);



  // ==========================================================================================================
  // main menu buttons functionalities start

  // functionality of the pin button

  const createMarker = function (lngLat: mapboxgl.LngLat) {
    let marker = new mapboxgl.Marker({
      color: "var(--ion-color-secondary-tint)",
      draggable: true
    }).setLngLat(lngLat);
    return marker;
  }

  const PinLocation = () => {
    setDisplayStartConnection(false);
    toast("Click on the map to define the connection site");
    siteType = SiteType.SinglePoint;
    map.current.getCanvas().style.cursor = "crosshair";
    map.current.once('click', (event: any) => {
      event.preventDefault();
      console.log(event);
      setDisplayAddressInfo(true);
      // create a marker(pin) and place it on the map
      // allow it to be able to be dragged around
      marker.current = createMarker(event.lngLat);
      marker.current.addTo(map.current);
      // add pin to the stack
      // mapItems.pins.push(marker.current);
      // mapItems.kinds.push(0);
      // mapItems.capacities.push('nan');
      // mapItems.names.push('pin');
      map.current.getCanvas().style.cursor = "unset";
      console.log(event);
      renderLocationPopup(event.lngLat, 0);
      let m = marker.current;
      marker.current.on('dragend', function (dEvent: any) {
        renderLocationPopup(m.getLngLat(), 0);
      })
    });
  }


  // lots of the code below taken from mapbox tutorial:
  // functionality for the area button
  const AreaLocation = () => {
    setDisplayStartConnection(false);
    siteType = SiteType.Area;
    setDefineArea(true);
    //setDefineSite(true);
    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      // select control buttons for the polygon drawing feature
      controls: {
        polygon: true,
        // line_string: true,
        trash: true

      },
      styles: getMapboxDrawStyle(),
      defaultMode: 'draw_polygon',
    });
    map.current.addControl(draw.current);

    map.current.on('draw.create', () => {
      try {
        console.log(draw.current);
        if (draw.current == null) {
          return;
        }
        setDisplayAddressInfo(true);
        const data = draw.current.getAll();
        areas.current = data;
        currentArea = data;
        const databbox = turf.bbox(data);
        poly.current = turf.bboxPolygon(databbox);

        let centroid = turf.centroid(data);
        let ll = new mapboxgl.LngLat(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]);
        renderLocationPopup(ll, 0);
      } catch (e) {

      }
    });
    map.current.on('draw.update', (event: any) => {
      if (draw.current == null) return;
      setDisplayAddressInfo(true);
      try {
        const data = draw.current.getAll();
        const databbox = turf.bbox(data);
        areas.current = data;
        currentArea = data;
        poly.current = turf.bboxPolygon(databbox);

        let centroid = turf.centroid(data);
        let ll = new mapboxgl.LngLat(centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]);
        renderLocationPopup(ll, 0);
      } catch (e) {

      }
      //let closest = findClosest(event, 0).getLngLat();

      //   // if a new pin created, add its address to the stack
      //   if (mapItems.pins.length > mapItems.names.length) mapItems.names.push(await locationName(closest, 0));

      //   // if clicked close enouch to the pin (within 50m), display the popup
      //   if ((event.lngLat).distanceTo(closest) < 50) renderLocationPopup(closest, 0);

    });
    map.current.on('draw.delete', () => {
      if (draw.current == null) return;
      try {
        const data = draw.current.getAll();
        const databbox = turf.bbox(data);
        areas.current = data;
        currentArea = data;
        poly.current = turf.bboxPolygon(databbox);
      } catch (e) {

      }
    });
  }



  // functionality of the undo button
  const UndoButton = () => {
    /*
    // remove the connection, if it's on the map
    if (map.current.getLayer('connection-lines')) {
      map.current.removeLayer('connection-lines');
      map.current.removeSource('connection');
      // reset the variables to allow for redrawing the connection
      connection = new Array<any>();
      lastViewed = new Array<any>();
      chosenPoint = new Array<any>();
    }

    // close the form if active
    // else if (displaySiteDetails) {
    //   setDisplaySiteDetails(false);
    // }

    // if device pins are on the screen start removing them first
    else if (mapItems.pins.length > 0 && mapItems.kinds[mapItems.pins.length - 1] === 2) {
      let mark = mapItems.pins.pop();
      mapItems.names.pop();
      mapItems.kinds.pop();
      mapItems.capacities.pop();
      mark.remove();
      mark = null as unknown as mapboxgl.Marker;
    }

    // remaining scenarios: device menu, cables and location pin/area
    else {
      // if no device pins and device menu active - close device menu
      if (displayDeviceMenu) {
        setDisplayDeviceMenu(false);
      }

      // if cables shown, remove those
      else if (map.current.getLayer('network-lines-over')) {
        map.current.removeLayer('network-lines-over');
        map.current.removeLayer('network-lines');
        map.current.removeSource('network');
      }

      // if pin on the map - remove the most recently added pin
      else if (mapItems.pins.length > 0 && mapItems.kinds[mapItems.pins.length - 1] === 0) {
        let mark = mapItems.pins.pop();
        mapItems.names.pop();
        mapItems.kinds.pop();
        mapItems.capacities.pop();
        mark.remove();
        mark = null as unknown as mapboxgl.Marker;
      }

      // remove the area if exists
      else if (draw.current) {
        mapItems.pins.pop();
        mapItems.names.pop();
        mapItems.kinds.pop();
        mapItems.capacities.pop();
        draw.current.deleteAll();
        map.current.removeControl(draw.current);
      }
    }
    */
  }



  // functionality of the style button
  const styleButton = async () => {
    connectionSource = map.current.getSource('connection');

    // if the original style so far, and button clicked - update the state and style
    if (!satelliteStyle) {
      setSatelliteStyle(true);
      await map.current.setStyle(SATELLITE_STYLE);
    }
    // if style has been changed and button clicked, revert to original
    else {
      setSatelliteStyle(false);
      await map.current.setStyle(DEFAULT_STYLE);
    }
    // idea - check if they exist, and readd them if they don't
    await map.current.on('style.load', () => { updateLayersOnChange(); });
  }


  // functionality of the edit button
  const editButton = async () => {
    // remove the old connection
    if (map.current.getSource('connection')) {
      connectionSource = map.current.getSource('connection');
      map.current.removeLayer('connection-lines')
      map.current.removeSource('connection')
    }
    // redraw the connection on change(one action per click)
    map.current.once('draw.update', () => {
      newRoute = JSON.parse(JSON.stringify(draw.current.getAll().features[pos].geometry.coordinates))
      addColouredLayers(newRoute);
    })
  }

  const finaliseArea = () => {
    setDefineSite(true);

    if (siteType == SiteType.SinglePoint) {
      setDefineArea(false);
    } else {
      setDefineArea(false);
      setArea = true;
      let fc = draw.current.getAll();
      draw.current.deleteAll();
      map.current.removeControl(draw.current);
      map.current.addSource('site-area', {
        type: "geojson",
        data: fc
      });
      let bbox = new mapboxgl.LngLatBounds(poly.current.bbox);
      console.log(poly.current);

      map.current.fitBounds(bbox, { padding: 20, duration: 3000 });

      map.current.addLayer({
        'id': 'site-area-fill',
        'type': 'fill',
        'source': 'site-area', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': colourPri, // blue color fill
          'fill-opacity': 0.2
        }
      });

      map.current.addLayer({
        'id': 'site-area-outline',
        'type': 'line',
        'source': 'site-area',
        'layout': {},
        'paint': {
          'line-color': colourPri,
          'line-width': 3
        }
      });
    }
  }

  // ========================================================================================================================

  // function for finding the starting point of the connection, when connecting an area(either manually or automatically)
  function findStart(finish: any) {
    // arbitrary starting point, and coordinates of the area
    let start = new mapboxgl.LngLat(0, 0);
    let areaCoords = areas.current.features[0].geometry.coordinates[0];
    console.log(finish);
    let point = finish;

    let distance = -1;
    for (let i = 0; i < areaCoords.length - 1; i++) {
      // take the midpoint of each edge and check how far away it is from the first point of the connection outside of the area
      let mid = new mapboxgl.LngLat((areaCoords[i][0] + areaCoords[i + 1][0]) / 2, (areaCoords[i][1] + areaCoords[i + 1][1]) / 2);

      let n = project({ x: point.lng, y: point.lat }, { x: areaCoords[i][0], y: areaCoords[i][1] }, { x: areaCoords[i + 1][0], y: areaCoords[i + 1][1] });
      let p = new mapboxgl.LngLat(n.point.x, n.point.y);
      let d = p.distanceTo(point);

      if (distance == -1 || d < distance) {
        start.lng = p.lng;
        start.lat = p.lat;
        distance = d;
      }
    }
    return start;
  }


  // function for finding the end point of the connection
  async function findFinish(start: any, distance: any, route: boolean) {


    let connectionPoint = await fetch(SERVER_URL + "/cimphony/concert/connections/nearest/" + geoLocatedNetwork.current + "/@" + start.lng + "," + start.lat + "?voltage=" + formData.voltage + "&route=" + route + "&username=" + getUser() + "&key=" + getKey(), { method: 'GET' }).
      then(res => res.json());
    let nearest = findNearestLinePoint(map, start, 'none');
    console.log(nearest);
    console.log(connectionPoint);
    return connectionPoint;
    /*
    let location: any;
    let finish = new mapboxgl.LngLat(0, 0);
    // find the closest connection point
    map.current.queryRenderedFeatures().forEach((e: any) => {
      if (e.toJSON().layer.id === 'network-lines') {
        console.log(e);
        for (let i = 0; i < e.toJSON().geometry.coordinates.length; i++) {
          // filter out the results
          if (!Array.isArray(e.toJSON().geometry.coordinates[i][0])) {
            location = new mapboxgl.LngLat(e.toJSON().geometry.coordinates[i][0], e.toJSON().geometry.coordinates[i][1]);
            if (location.distanceTo(start) < distance) {
              finish = location;
              distance = location.distanceTo(start);
            }
          }
        }
      }
    });
    console.log(finish);
    return finish;
    */
  }


  // function to find the pin clicked on (pin closest to 'where was clicked')
  const findClosest = function (lngLat: mapboxgl.LngLat): Device | null {
    // find the first pin of the same type in the list of locations
    let closest: Device | null = null;
    let closestDistance: number = 0;
    console.log(devices);
    devices.forEach((value: Device, key: string) => {
      let d = lngLat.distanceTo(value.pin.getLngLat());
      if (closest == null || d < closestDistance) {
        closest = value;
        closestDistance = d;
      }
    });
    // for (let i = 0; i < mapItems.pins.length; i++) {
    //   if (mapItems.kinds[i] === kind) {
    //     closest = mapItems.pins[i];
    //     break;
    //   }
    // }
    // // find a pin closest to the click event
    // for (let i = 1; i < mapItems.pins.length; i++) {
    //   if (mapItems.kinds[i] === kind && (event.lngLat.distanceTo((closest as mapboxgl.Marker).getLngLat()) > event.lngLat.distanceTo((mapItems.pins[i] as mapboxgl.Marker).getLngLat()))) {
    //     closest = mapItems.pins[i];
    //   }
    // }
    return closest;
  }


  const locationName = async function (closest: any, detailLevel: number) {
    // reverse query for address given coordinates
    address = await fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/" + closest.lng + "," +
      closest.lat + ".json?access_token=" + mapboxgl.accessToken)
      .then(res => res.json());
    return address.features[detailLevel]['place_name']
  }




  // connect device or area with devices to the network
  const connectDevice = function () {
    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      // select control buttons for the polygon drawing feature
      controls: {
        //polygon: true,
        line_string: true,
        trash: true

      },
      styles: getMapboxDrawStyle(),
      defaultMode: 'draw_line_string',
    });
    map.current.addControl(draw.current);

    let len = draw.current.getAll().features.length;
    let coords = draw.current.getAll().features[len - 1].geometry.coordinates; // coordinates of the last draw object - connection line

    // no coordinates chosen yet - choose the starting point
    if (chosenPoint.length === 0) {
      // if not connected yet, and a single device on the map
      if (siteType == SiteType.SinglePoint && connection.length === 0) {
        // and if started drawing the connection close enough to the device, add start coordinates 
        if (devices.values().next().value.pin.getLngLat().distanceTo(new mapboxgl.LngLat(coords[0][0], coords[0][1])) < 20) {
          chosenPoint.push(coords[0]);
          pos = 0;
        }
      }
      // if not connected yet, and an area drawn on the map
      else if (siteType == SiteType.Area && connection.length === 0) {
        // and if started drawing the connection from the area, add start coordinates
        if ((new mapboxgl.LngLatBounds(poly.current.bbox)).contains(coords[0])) {
          let distance = 1000000; // some initial very big distance to compare the points to
          let startPoint = findStart(new mapboxgl.LngLat(coords[1][0], coords[1][1]));
          chosenPoint.push([startPoint.lng, startPoint.lat]);
          pos = 1;
        }
      }
    }
    // choose a connection point - the final coordinate
    else if (chosenPoint.length === 1) {
      // if final point is near the network and voltage is the same as chosen, add coordinates, and consider connection done
      if (new mapboxgl.LngLat(lastViewed[0], lastViewed[1]).distanceTo(new mapboxgl.LngLat(coords[coords.length - 1][0], coords[coords.length - 1][1])) < 5) {
        chosenPoint.push(new mapboxgl.LngLat(lastViewed[0], lastViewed[1]));
        connection.push(chosenPoint);

        newRoute = JSON.parse(JSON.stringify(draw.current.getAll().features[pos].geometry.coordinates)) // deep copy of coordinates
        // set the right starting point: if single device, no change; if area, centre of mass(might change to the border later)       
        newRoute[0] = chosenPoint[0]

        // colour over the "forbidden" terrain types
        addColouredLayers(newRoute);
      }
    }
  }

  const getMapPoint = function () {
    console.log(siteType);
    if (siteType == SiteType.SinglePoint) {
      if (marker.current != null)
        return marker.current?.getLngLat();
      else if (devices.size > 0)
        return devices.values().next().value.pin.getLngLat();
    } else if (siteType == SiteType.Area) {
      console.log(areas.current);
      let centroid = turf.centroid(areas.current);
      console.log(centroid);
      return new mapboxgl.LngLat(
        centroid.geometry.coordinates[0],
        centroid.geometry.coordinates[1]);;
    }
  }

  function manualConnection() {
    //if (draw != null && draw.current != null && draw.current.getAll() && draw.current.getAll().features[draw.current.getAll().features.length - 1].geometry.type === 'LineString') {
    //connectDevice();
    let point: any;
    let device: Device | null = null;
    if (siteType == SiteType.SinglePoint) {
      device = devices.values().next().value;
    }

    setShowRoutingInfo(true);
    startManuallyConnectingDevice(map, null, siteType, device, areas, setShowConfirmConnection);;
    //  setStartConnection(true);;
    //}


  }

  async function autoConnection() {
    setShowRoutingInfo(true);

    let device: Device | null = null;
    if (siteType == SiteType.SinglePoint) {
      device = devices.values().next().value;
    }
    // if (map.current.getZoom() > 15) {
    //   map.current.setZoom(15);
    // }
    const runAutoRoute = async function () {
      let start = new mapboxgl.LngLat(0, 0);
      // if a single location, start where the pin is, otherwise the centre of the area
      if (siteType == SiteType.SinglePoint) {
        start = devices.values().next().value.pin.getLngLat();
        //start = (mapItems.pins[0] as mapboxgl.Marker).getLngLat();
      }
      else if (siteType == SiteType.Area) {
        let centre = turf.centerOfMass(turf.polygon(areas.current.features[0].geometry.coordinates)).geometry.coordinates; // centre of the area is a strating point for now
        start = new
          mapboxgl.LngLat(centre[0], centre[1]); // use centre of the area for now, migth come up with sth better later (border closest to the network or sth)
      }

      console.log(start);
      // placeholders and dummy values
      let distance = 1000000;
      let finishPoint = await findFinish(start, distance, true);
      console.log(finishPoint);
      // set the connection start to be at a border
      let finish = new mapboxgl.LngLat(finishPoint.point[0], finishPoint.point[1]);
      if (siteType == SiteType.Area)
        start = findStart(finish);

      console.log(start);
      console.log(finish);
      let route;
      if (finishPoint.route != null) {
        route = finishPoint.route;
      } else {
        // get the directions from start to finish - using mapbox directions API
        let query = await fetch('https://api.mapbox.com/directions/v5/mapbox/walking/' + start.lng + ',' + start.lat + ';' + finish.lng + ',' + finish.lat + '?overview=full&geometries=geojson&access_token=' + mapboxgl.accessToken, { method: 'GET' }).
          then(res => res.json());
        // get the coordinates of the connection path and its length
        route = query.routes[0].geometry.coordinates;
      }
      let length = turf.length(turf.lineString(route))

      // if connection longer than 150m, go half or third of the way(depending on the length), and recalculate the path from there - make it shorter
      // else, no change
      /*
      if (length > 0.150) {
        // go either a third or half a way before finding a new path
        let segmentLength = Math.floor(route.length / 3);
        if (length > 0.5) segmentLength = Math.floor(route.length / 2);

        // find the new connection point starting at where we stopped, and query for the path again
        finish = await findFinish(new mapboxgl.LngLat(route[Math.floor(segmentLength)][0], route[Math.floor(segmentLength)][1]), distance);

        let secondQuery = await fetch('https://api.mapbox.com/directions/v5/mapbox/walking/' + route[Math.floor(segmentLength)][0] + ',' + route[Math.floor(segmentLength)][1] + ';' + finish.lng + ',' + finish.lat + '?overview=full&geometries=geojson&access_token=' + mapboxgl.accessToken,
          { method: 'GET' }).then(res => res.json());

        // once we've got the path, take the half/third of the old path and the rest is the new path
        newRoute = route.slice(0, Math.floor(segmentLength)).concat(secondQuery.routes[0].geometry.coordinates)
        newRoute.unshift([start.lng, start.lat]); // make sure tha path starts where the icons are
      } else { newRoute = route; }
      */
      let newRoute = route;
      // colour over the "forbidden" terrain types - if over the builidng or water show it on the map(add a popup)
      let autorouted: any = null;
      if (newRoute != null) {
        autorouted = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature", properties: {},
              geometry: {
                type: "LineString",
                coordinates: newRoute
              }
            }
          ]
        }
      }
      console.log(autorouted);
      if (autorouted != null) {
        autorouted.features[0].geometry.coordinates.unshift([start.lng, start.lat]);
        autorouted.features[0].geometry.coordinates.push([finish.lng, finish.lat]);
      }
      setShowRoutingInfo(true);
      startManuallyConnectingDevice(map, autorouted, siteType, device, areas, setShowConfirmConnection);;

    };
    runAutoRoute();
    // const awaitZoom = function () {
    //   if (map.current.getZoom() > 15) {
    //     console.log("Awaiting map zoom");
    //     window.setTimeout(function () { awaitZoom() }, 50);
    //   } else {
    //     window.setTimeout(function () {
    //       runAutoRoute();
    //     }, 250);
    //   }
    // };
    // awaitZoom();
  }




  // mouse over the cable handler
  const handleOverlay = function (e: any) {
    // Change the cursor style as a UI indicator.
    map.current.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const properties = e.features[0].properties;
    let coordinates: any;

    if (e.features[0].geometry.type == "Point")
      coordinates = e.features[0].geometry.coordinates.slice();
    else
      coordinates = [e.lngLat.lng, e.lngLat.lat];
    console.log(e.features);
    // display a popup to show info about a cable
    if (properties.layer == formData.voltage) {
      // last viewed point becomes the possible connection point if correct voltage
      lastViewed = coordinates;
      let popupConnect = document.createElement('div');
      ReactDOM.render(
        <>
          <PopupConnection name={properties.name} under={properties.underground} layer={properties.layer} volt={properties.voltage} />
        </>
        , popupConnect);
      mapboxPopup.setLngLat(coordinates).setDOMContent(popupConnect).addTo(map.current);
    }
    // allow the user to connect to the network
    if (draw != null && draw.current != null && draw.current.getAll() && draw.current.getAll().features[draw.current.getAll().features.length - 1].geometry.type === 'LineString') {
      //map.current.on('click', () => { connectDevice(); setStartConnection(true); });
    }
  };

  const renderLocationPopup = async function (location: any, detailLevel: number) {
    // display a popup
    //let popup = document.createElement('div');
    console.log(location);
    let address = await locationName(location, detailLevel);
    selectedAddress.current = address;
    console.log(address);
    let addrInfo = jQuery("#connections-address-info-details");
    addrInfo.empty();
    let parts = address.split(",");
    parts.forEach((part: string) => { jQuery("<div>").appendTo(addrInfo).text(part) });
    if (!formData.set && siteType == SiteType.SinglePoint) {
      setDefineSite(true);
    }
    //addrInfo.text(address);
    //toast(address, mapOutline, 4000);

    // ReactDOM.render(
    //   <>
    //     <PopupConfirm address={await locationName(location, detailLevel)} confirm={ConfirmPinLocation} />
    //   </>
    //   , popup);
    // mapboxPopup.setLngLat(location).setDOMContent(popup).addTo(map.current);
  }




  // function to verify the terrain type 
  const verifyConnectionSegment = function (props: any, distance: any) {
    let terrain = { hasRoad: false, hasWater: false, hasBuilding: false, hasLandcover: false, hasLanduse: false }; // assume no particular terrain type
    // go over the querried features and check terrain type - record it
    for (let l = 0; l < props.length; l++) {
      let currentLayer = props[l];

      if (currentLayer.sourceLayer === "road") terrain.hasRoad = true;
      else if (currentLayer.sourceLayer === "water") terrain.hasWater = true;
      else if (currentLayer.sourceLayer === "waterway") terrain.hasWater = true;
      else if (currentLayer.sourceLayer === "natural_label" && currentLayer.layer.id === 'waterway-label') terrain.hasWater = true;
      else if (currentLayer.sourceLayer === "building") terrain.hasBuilding = true;
      else if (currentLayer.sourceLayer === "landcover") terrain.hasLandcover = true;
      else if (currentLayer.sourceLayer === "landuse") terrain.hasLanduse = true;
      else continue;
    }
    // return the colour a particular segment should be coloured(rules can be changed depending on what behaviour is desired)
    if (distance <= 30 * DISTANCE_VALIDATION_INCREMENT) return '#22ff00';
    else if (terrain.hasRoad && terrain.hasWater) return '#ffff00';
    else if (!terrain.hasRoad && terrain.hasWater) return '#ff0000';
    else if (terrain.hasRoad && !terrain.hasWater) return '#22ff00';
    else if (terrain.hasBuilding) return '#ffff00';
    else if (terrain.hasLandcover) return '#22ff00';
    else if (terrain.hasLanduse) return '#22ff00'; // if set to yellow, the app slows down significantly
    else return '#22ff00'
  }


  // function for going over the connection line, and gathering information on colours in 'invalid' segments
  // for each segments it saves the start coordinates, colour value in hex, and end coordinates of the segment
  function explainInvalidTerrain() {
    let invalid: any[][] = []; // stores data about all the invalid segments
    let segment: any[]; // stores data about one invalid segment at a time
    let features = map.current.getSource('connection')['_options'].data.features;
    let green = true; // acts as an indicator of when a segment starts and ends

    features.forEach(function (feature: any) {
      // if colour changes from green to other save the colour and coordinates
      if (feature.properties.color !== "#22ff00" && green) {
        segment = [];
        green = false;
        segment.push(feature.geometry.coordinates[0]);
        segment.push(feature.properties.color)
      }
      // if colour changes from red/yellow to green, save end coordinates
      else if (feature.properties.color === "#22ff00" && !green) {
        green = true;
        segment.push(feature.geometry.coordinates[0]);
        invalid.push(segment);
      }
    })
    return invalid;
  }




  const ConfirmPinLocation = (choice: boolean) => {
    if (choice) {
      setDefineSite(true);
      //setDisplaySiteDetails(true); // location confirmed - show the form
    }//else setDisplaySiteDetails(false);// location discarded - disable the form
    // in both cases, address confirmation popup disappears
    mapboxPopup.remove();
  }


  function getMarkerElement() {

  }

  // functionality for adding device icons to the map
  function DeviceFunctionality(deviceType: DeviceType) {
    // just a single device
    console.log(deviceType);
    console.log(marker.current);


    if (siteType == SiteType.SinglePoint && marker.current != undefined) {
      // loc: where to place the device
      let loc = marker.current.getLngLat(); // index of 0: we assume only 1 location or 1 area can be chosen

      // create a div for an icon marker
      const el = document.createElement('div');
      const markerDiv = jQuery("<div>").addClass("connections-map-device-marker-container").appendTo(jQuery(el));
      ReactDOM.render(
        <>
          <span className="connections-map-device-marker"><FontAwesomeIcon swapOpacity icon={deviceType.icon} /></span>
        </>
        , markerDiv[0]
      );
      //el.style.backgroundImage = 'url(' + props[1] + ')';
      // add styling
      el.style.width = '2em';
      el.style.height = '2em';
      el.style.backgroundSize = '2em';

      // remove the old pin
      // mapItems.pins[0].remove();
      // create a marker object with an icon and add to the map
      let mapMarker = new mapboxgl.Marker(el);
      mapMarker.setLngLat(loc);
      mapMarker.addTo(map.current);

      marker.current.remove();
      marker.current = undefined;
      // mapItems.pins[0] = mapMarker;
      // mapItems.pins[0].setLngLat(loc)
      //   .addTo(map.current);

      // // replace the old pin with the new device
      // mapItems.kinds[0] = 2;
      // mapItems.names[0] = deviceType.name;
      // mapItems.capacities[0] = '0';



      const device: Device = new Device(uuid(), deviceType, mapMarker);
      devices.set(device.id, device);

      selectedDevice = device;
      setDisplayDeviceCharacteristics(true);
      //map.current.fire('click', {lngLat:loc});

      // close the device menu
      //setDisplayDeviceMenu(false);
    }
    // adding devices within a selected area
    else if (siteType == SiteType.Area) {
      // allow only one icon per button click(but multiple icons in general - can press the button as many times as necessary)

      let message = "Click within the site area to create a new " + deviceType.name;
      toast(message, pin, 5000);

      let handleClick = function (event: any) {
        // only place the device if clicked within the boundaries of the area
        let inside = false;
        console.log(areas.current);
        for (let i = 0; i < areas.current.features.length; i++) {
          let c = turf.getCoord([event.lngLat.lng, event.lngLat.lat]);
          let geom = areas.current.features[i].geometry;
          if (turf.inside(c, geom)) {
            inside = true;
            console.log("Inside");
          }
        }


        if (inside) {
          //if ((new mapboxgl.LngLatBounds(poly.current.bbox)).contains(event.lngLat)) {
          const el = document.createElement('div');
          const marker = jQuery("<div>").addClass("connections-map-device-marker-container").appendTo(jQuery(el));
          ReactDOM.render(
            <>
              <span className="connections-map-device-marker"><FontAwesomeIcon icon={deviceType.icon} /></span>
            </>
            , marker[0]
          );

          el.style.width = '67px';
          el.style.height = '62px';
          el.style.backgroundSize = '67px';

          // create a marker object with an icon and add to the map
          const mapMarker = new mapboxgl.Marker(el);
          mapMarker.setLngLat(event.lngLat)
            .setDraggable(true)
            .addTo(map.current);

          const device: Device = new Device(uuid(), deviceType, mapMarker);
          devices.set(device.id, device);

          // add device to the stack
          // mapItems.pins.push(mark);
          // mapItems.names.push(deviceType.name);
          // mapItems.kinds.push(2);
          // mapItems.capacities.push('0');
        } else {
          toast("Location outsite side area", warning);
          map.current.once('click', handleClick);
        }
      }

      map.current.once('click', handleClick);
    }
  }


  // behaviour of form submission
  function submitForm() {
    // close the form
    ///setDisplaySiteDetails(false);
    // display the device menu


    if (siteType == SiteType.SinglePoint)
      setDisplayDeviceMenu(true);
    else
      setDisplayDevicePalette(true);
    // Add a heatmap layer for the circuits to be drawn

    updateNetworkSource();

    addNetworkLayer();
    if (getCapabilities().routing){
      setCanFinishDeviceEditing(true);
      setShowConnectionInfo(true);
    }else{
      setShowDetailsComplete(true);
    }

    let connInfo = jQuery("#connections-connection-info-details");
    console.log(connInfo);
    connInfo.empty();

    ReactDOM.render(
      <>
        <div>
          {formData.type == "industrial" ? <FontAwesomeIcon icon={faIndustry} /> : <></>}
          {formData.type == "commercial" ? <FontAwesomeIcon icon={faBuildings} /> : <></>}
          {formData.type == "residential" ? <FontAwesomeIcon icon={faHouseChimney} /> : <></>}
          <span>&nbsp;{formData.type.substring(0, 1).toUpperCase()}{formData.type.substring(1)}</span>
        </div>
        <div>
          {formData.phase == "single-phase" ? <FontAwesomeIcon icon={faPlug} /> : <></>}
          {formData.phase == "three-phase" ? <FontAwesomeIcon icon={faTransformerBolt} /> : <></>}
          {formData.phase == "single-phase" ? <span>&nbsp;Single Phase&nbsp;</span> : <></>}
          {formData.phase == "three-phase" ? <span>&nbsp;Three Phase&nbsp;</span> : <></>}
          <span>
            {formData.voltage == "LV" ? "LV" : formData.voltage + " KV"}
          </span>
        </div>
      </>
      , connInfo[0]
    );
  }


  function fileContent() {
    let gjson = JSON.stringify(map.current.getSource('connection')['_data']);

    let contents = "address_line_1: " + formData.address1 + "\naddress_line_2: " + formData.address2 + "\npostcode: " + formData.postcode + "\ncity: " + formData.city + "\nphase: " + formData.phase +
      "\ntype: " + formData.type + "\nvoltage: " + formData.voltage + "\ngeojson:\n" + gjson;
    return [contents];
  }



  // display connection info after connecting device(s) to the network
  function displaySummary() {
    let connectionLength = turf.length(turf.lineString(newRoute));
    let yellowTerrain: number[] = [0, 0];
    let redTerrain: number[] = [0, 0];

    // go over all the non-green(invalid) connection segments and record the count
    invalid.forEach((el: any) => {
      if (el[1] === '#ffff00') {
        yellowTerrain[0]++;
        yellowTerrain[1] = yellowTerrain[1] + turf.length(turf.lineString([el[0], el[2]]))
      }
      else if (el[1] === '#ff0000') {
        redTerrain[0]++;
        redTerrain[1] = redTerrain[1] + turf.length(turf.lineString([el[0], el[2]]))
      }
    });
    let cost = yellowTerrain[1] * 1000 * 100 + redTerrain[1] * 1000 * 150 + (connectionLength - yellowTerrain[1] - redTerrain[1]) * 1000 * 75; // made up values-purely for demonstration purposes

    // create a downloadable file object with the geojson data
    let file = new File(fileContent(), "connection.txt", { type: 'text/plain' });
    let url = URL.createObjectURL(file);

    let popup = document.createElement('div');
    ReactDOM.render(
      <>
        <ConnectionSummary length={Math.round(connectionLength * 1000 * 100) / 100} yellow={yellowTerrain[0]} red={redTerrain[0]} cost={Math.round(cost * 100) / 100} map={map} file={url} />
      </>
      , popup);
    mapboxPopup.setLngLat(newRoute[newRoute.length - 1]).setDOMContent(popup).addTo(map.current);

    // remove the draw object
    // if area -> cable connection drawn
    if (draw.current && draw.current.getAll().features.length > 1) {
      draw.current.delete(draw.current.getAll().features[pos].id)
    }
    // if pin -> cable connection drawn
    else if (draw.current && draw.current.getAll().features.length === 1) {
      draw.current.delete(draw.current.getAll().features[pos].id);
      map.current.removeControl(draw.current);
      // mapItems.capacities.pop();
      // mapItems.kinds.pop();
      // mapItems.pins.pop();
      // mapItems.names.pop();
    }
    if (startConnection) setStartConnection(false);
  }


  // ==================================================================================================================================================================================================================================
  // functions for adding and amnipulating particular sources or layers start

  async function updateNetworkSource() {
    console.log("Updating network source");
    let point = getMapPoint();
    console.log(point);
    if (point != null) {
      let network: any = null;
      try {
        let networks = await fetch(SERVER_URL + "/cimphony/concert/connections/geo/intersects/@" + point.lng + "," + point.lat + "?username=" + getUser() + "&key=" + getKey(), { method: 'GET' }).
          then(res => res.json());
        console.log(network);
        if (networks != null && networks.length > 0) {
          console.log("Using " + networks[0].name);
          network = networks[0].ID
        }
      } catch (e) {

      }
      if (network != null) {
        geoLocatedNetwork.current = network;
      } else {
        geoLocatedNetwork.current = "72bf6fc7-a95b-4793-b61a-3ff8efa06426";
      }
    } else {
      geoLocatedNetwork.current = "72bf6fc7-a95b-4793-b61a-3ff8efa06426";
    }
    currentNetwork = geoLocatedNetwork.current;

    let layers = "primary,secondary,lv";
    let tileURL = SERVER_URL + "/gridiview/tiles?network=" + geoLocatedNetwork.current + "&zoom={z}&x={x}&y={y}&highDPI=false&username=" + getUser() + "&key=" + getKey() + "&type=lines&format=pbf&layers=" + layers;
    console.log(tileURL);
    if (map.current.getSource('network')) {
      map.current.getSource('network').tiles = [tileURL];
      map.current.style._sourceCaches['other:network'].clearTiles();
      map.current.style._sourceCaches['other:network'].update(map.current.transform)
      map.current.triggerRepaint();
    } else {

      map.current.addSource('network', {
        type: 'vector',
        tiles: [
          tileURL
        ],
        tilesSize: 512,
        maxzoom: 17,
        minzoom: 6
      });
    }
  }

  async function addNetworkSource(layers: string, point: any) {
    // Create the data source from the converted GeoJSON for circuits and stations
    console.log("adding network source");
    console.log(map.current.getLayer('network-lines'));
    updateNetworkSource();
    /*
    point = map.current.getCenter();
    if (point!=null){
     let network : any = null;
     try{
       network = await fetch(SERVER_URL+"/cimphony/concert/db/orchestration/geo/intersects/@"+point.lng+","+point.lat+"?username="+getUser()+"&key="+getKey(), { method: 'GET' }).
       then(res => res.json());
     }catch (e){
 
     }
     if (network!=null && network.length > 0){
       geoLocatedNetwork.current = network[0];
     }else{
       geoLocatedNetwork.current = "72bf6fc7-a95b-4793-b61a-3ff8efa06426";
     }
   }else{
     geoLocatedNetwork.current = "72bf6fc7-a95b-4793-b61a-3ff8efa06426";
   }
   
     console.log(geoLocatedNetwork.current);
     map.current.addSource('network', {
       type: 'vector',
       tiles: [
         SERVER_URL+"/gridiview/tiles?network="+geoLocatedNetwork.current+"&zoom={z}&x={x}&y={y}&highDPI=false&username="+getUser()+"&key="+getKey()+"&type=lines&format=pbf&layers=" + layers
       ],
       tilesSize: 512,
       maxzoom: 17,
       minzoom: 6
     });
     */
  }


  function addNetworkLayer() {
    if (map.current.getLayer('network-lines') != null)
      return;

    console.log(formData.networkKind);
    map.current.addLayer({
      'id': 'network-lines',
      'type': 'line',
      'source': 'network',
      'source-layer': 'lines',
      'filter': ['==', 'layer', formData.networkKind],
      'maxzoom': 22,
      'paint': {
        "line-width": 5,
        "line-color": {
          "property": "voltage",
          "stops": getVoltageStops()
        }
      }
    });
    // add a transparent top layer for the cables
    // make the cables wider to make them easier to click on
    // while having thin and clean lines displayed to the user
    map.current.addLayer({
      'id': 'network-lines-over',
      'type': 'line',
      'source': 'network',
      'source-layer': 'lines',
      'filter': ['==', 'layer', formData.voltage],
      'paint': {
        "line-width": 20,
        "line-color": "rgba(0,0,0,0)"
      }
    });
  }


  function addConnectionLayer() {
    if (!map.current.getLayer('connection-lines')) {
      map.current.addLayer({
        'id': 'connection-lines',
        'type': 'line',
        'source': 'connection',
        'paint': {
          'line-width': 6,
          'line-color': ['get', 'color']
        }
      })
    }
  }



  // function to colour in the 'forbidden' terrain types - adds source and layer
  const addColouredLayers = function (coords: any) {
    // move a meter at a time along the connection
    for (let i = 0; i < turf.length(turf.lineString(coords), { units: 'kilometers' }); i = i + DISTANCE_VALIDATION_INCREMENT) {
      // the diagonal of each bbox(area of the map to query) is going to be: every next line segment of length 10 meters
      let start = turf.along(turf.lineString(coords), i, { units: 'kilometers' });
      let end = turf.along(turf.lineString(coords), i + DISTANCE_VALIDATION_INCREMENT, { units: 'kilometers' })

      let bbox = turf.bbox(turf.featureCollection([start, end]))
      // convert bbox to pixel coordinates - not sure which one to choose lol, not great with coordinates
      let swPoint = map.current.project(new mapboxgl.LngLat(Math.min(bbox[0], bbox[2]), Math.min(bbox[1], bbox[3])));
      let nePoint = map.current.project(new mapboxgl.LngLat(Math.max(bbox[0], bbox[2]), Math.max(bbox[1], bbox[3])));

      let valid = '#22ff00'; // indicates the color of the connection

      // query the layers within the bounding box
      let allSourceData = map.current.queryRenderedFeatures([[swPoint.x, swPoint.y], [nePoint.x, nePoint.y]]);

      // update the validation variable, update the connection layer, and move to the next point
      valid = verifyConnectionSegment(allSourceData, i);

      // if connection hasn't started, create the new source for it
      if (map.current.getSource('connection') === undefined) {
        map.current.addSource('connection', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'properties': {
                  'color': valid
                },
                'geometry': {
                  'type': 'LineString',
                  'coordinates': [start.geometry.coordinates, end.geometry.coordinates]
                }
              }
            ]
          }
        })
      } else { // if the source exists, update it with new line segment
        let source = map.current.getSource('connection')['_options'];
        source.data.features.push({
          'type': 'Feature',
          'properties': {
            'color': valid
          },
          'geometry': {
            'type': 'LineString',
            'coordinates': [start.geometry.coordinates, end.geometry.coordinates]
          }
        });
        // update the source
        map.current.getSource('connection').setData(source.data);
      }
    }
    // show the connection drawn by the user - make it a layer
    if (map.current.getSource('connection')) {
      addConnectionLayer()
    }
    invalid = explainInvalidTerrain();
  }



  const updateLayersOnChange = () => {
    if (!map.current.getSource('network')) {
      // Create the data source from the converted GeoJSON for circuits and stations
      addNetworkSource("primary,secondary,lv", getMapPoint());
    }
    // if source loaded, and changing styles after filling out the form(if we add before, the app breaks)
    if (map.current.getSource('network') && !map.current.getLayer('network-lines') && formData.address1 !== '') {
      addNetworkLayer();
    }
    if (newRoute) {
      if (!map.current.getSource('connection')) {
        map.current.addSource('connection', connectionSource['_options'])
        // show the connection drawn by the user - make it a layer
        if (map.current.getSource('connection')) {
          addConnectionLayer()
        }
      }
    }
  }


  // function for restoring the connection from the uploaded file
  const useFile = (e: any) => {
    let files = e.target.files[0];
    let reader = new FileReader();
    let content: any;

    // going over the file, saving appropriate data(kind of hardcoded the structure of the file and substrings' offsets)
    reader.readAsText(files);
    reader.onload = () => {
      content = reader.result;
      let lines = content.split(/\r\n|\n/);
      for (let i = 0; i < lines.length - 1; i++) {
        if (lines[i].includes('address_line_1')) formData.address1 = lines[i].substr(16);
        else if (lines[i].includes('address_line_2')) formData.address2 = lines[i].substr(16);
        else if (lines[i].includes('postcode')) formData.postcode = lines[i].substr(10);
        else if (lines[i].includes('city')) formData.city = lines[i].substr(6);
        else if (lines[i].includes('phase')) formData.phase = lines[i].substr(7);
        else if (lines[i].includes('type')) formData.type = lines[i].substr(6);
        else if (lines[i].includes('phase')) formData.phase = lines[i].substr(7);
        else if (lines[i].includes('voltage')) formData.voltage = lines[i].substr(9);
        else break;
      }
      connectionSource = lines[8];

      // restore connection and network layers
      addNetworkLayer();
      map.current.addSource('connection', {
        'type': 'geojson',
        'data': JSON.parse(lines[8])
      });
      if (map.current.getSource('connection')) addConnectionLayer();
    }

  }
  // ==========================================================================================================================================================================================================

  const finishDeviceEditing = function () {
    setDisplayDevicePalette(false);
    //setStartConnection(true);
    setCanFinishDeviceEditing(false);
    inRouting = true;
    console.log(startConnection + " " + routeConnection + " " + canFinishDeviceEditing);
  }

  const routeManually = function () {
    setCanFinishDeviceEditing(false);
    setShowRoutingComplete(true);
    if (map.current.getZoom() > 17)
      map.current.easeTo({ zoom: 17 });
    manualConnection();
  }

  const routeAutomatically = function () {
    setCanFinishDeviceEditing(false);
    setShowRoutingComplete(true);
    if (map.current.getZoom() > 17)
      map.current.easeTo({ zoom: 17 });
    autoConnection();
  }

  const runCompletion = async function (action : any, onComplete : Function) {
    let type = action.action;
    console.log(type);
    let json = getConnectionDetailsJSON(areas.current);
    console.log(json);
    if (type == "submitProject"){
      let device = json.devices[Object.keys(json.devices)[0]];
      let type = "Unknown";
      if (device.type.id == "diesel") type = "Diesel";
      else if (device.type.id == "solar") type = "Solar";
      else if (device.type.id == "battery") type = "Battery";
      else if (device.type.id == "ev") type = "EV Charging Point";
      else if (device.type.id == "chp") type = "CHP";
      else if (device.type.id == "wind") type = "Wind";
      let data = {
        "DistributedEnergyResource_name" : device.name,
        "Manufacturer_name" : device.manufacturer,
        "ProductAssetModel_name" : device.model,
        "Asset_connectionStatus" : "Application Submitted",
        "PowerElectronicsConnection_ratedS" : device.generationCapacity / 1000,
        "PowerElectronicsConnection_ratedU" : json.form.voltage,
        "Asset_type" : type,
        "address" : json.form.address1.trim()+" "+json.form.address2.trim()+" "+json.form.city.trim(),
        "zipCode" : json.form.postcode,
        "PositionPoint_xPosition":device.lng,
        "PositionPoint_yPosition":device.lat,
        "user" : getUser()
      };

      console.log(data);
      const response = await fetch(SERVER_URL + "/cimphony/concert/connections/registerDER/"+getCapabilities().resources, {
        method: 'POST',
        body: JSON.stringify(data),
        headers:
          { 'Content-Type': 'application/json',
            'Authorization' :  "Basic " + btoa(getUser() + ":" + getKey())
          }
      });

      let jsonR = await response.json();
      console.log(jsonR);
      onComplete(true);
    }else{

      json.action = "generateConnection";
      json.returnType = type;

      console.log(json);

      let body = new URLSearchParams({
        'username': getUser(),
        'key': getKey(),
        'action': 'generateConnection',
        'returnType': type,
        'data': JSON.stringify(json)
      });

      const response = await fetch(SERVER_URL + "/cimphony/concert/connections", {
        method: 'POST',
        body: body,
        headers:
          { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });

      let exportId = "";
      let jsonR = await response.json();
      console.log(jsonR);
      exportId = jsonR.exportId;

      if (exportId!=null){
        let downloadUrl = "/cimphony/concert/db/bson/download/" + currentNetwork + "/" + exportId + "?profile=http://entsoe.eu/Profile/6/EQ_CO&correct=true&username=" + getUser() + "&key=" + getKey();
        window.open(SERVER_URL + downloadUrl, "_blank");
      }
    }
  }

  let self = this;
  const logOut = () => {
    setAccess(null, null);
    navigate('/home');
  }

  return (
    <>
      {/* main container/page */}
      <div id="container"> </div>


      {/* 3 static 'menu' buttons with instructions(available on right-click): */}

      <IonButton class="connections-button" id="styleButton" onMouseUp={styleButton.bind(this)}>
        <FontAwesomeIcon id="style-icon" icon={satelliteStyle ? faEarthAmericas : faSatellite} style={{ fontSize: "1.5em" }} /></IonButton>

      <IonButton class="connections-button" id="logoutButton" onMouseUp={logOut}>
        <FontAwesomeIcon id="style-icon" icon={faRightFromBracket} style={{ fontSize: "1.5em" }} /></IonButton>

      {displayStartConnection ?
        <StartConnection StatusFunction={setDefineSite} PinLocation={PinLocation} AreaLocation={AreaLocation} />
        : <></>}
      {displayAddressInfo ? <div className="connections-address-info-container">
        <IonLabel id="connections-address-info"><h2><FontAwesomeIcon swapOpacity icon={faMapMarked} />&nbsp;Address</h2>
          <div id="connections-address-info-details" className="connections-info-details"></div>
          {showConnectionInfo ? <br /> : <></>}
          {showConnectionInfo ? <h2><FontAwesomeIcon swapOpacity icon={faPlugCircleBolt} />&nbsp;Connection</h2> : <></>}
          <div id="connections-connection-info-details" className="connections-info-details"></div>
          {showRoutingInfo ? <br /> : <></>}
          {showRoutingInfo ? <h2><FontAwesomeIcon swapOpacity icon={faRoute} />&nbsp;Route</h2> : <></>}
          <div id="connections-routing-info-details" className="connections-info-details"></div>
        </IonLabel>
      </div> : <></>};
      {defineSite ? <ConnectionDetails siteType={siteType} DefineSite={setDefineSite} SubmitForm={submitForm} address={address.features[pos]['place_name'].split(",")} /> : <></>};
      {displayDeviceMenu ? <DeviceTypes deviceTypes={deviceTypes} setDisplayDeviceMenu={setDisplayDeviceMenu} DeviceFunctionality={DeviceFunctionality} address={selectedAddress} /> : <></>};
      {displayDeviceCharacteristics ? <DeviceCharacteristics siteType={siteType} devices={devices} map={map} marker={marker} createMarker={createMarker} device={selectedDevice} renderLocationPopup={renderLocationPopup} clearDevice={function () { setDisplayDeviceCharacteristics(false); selectedDevice = null }} /> : <></>}

      {/* show a form to fill out once the location has been confirmed */}

      {/* when form submitted, display a menu allowing to drop device icons */}
      {defineArea ? <div className="connections-continue-button-container"><IonButton className="connections-continue-button" id="defineAreaButton" onClick={finaliseArea}>Confirm Site Area&nbsp;<FontAwesomeIcon icon={faCaretRight} /></IonButton></div> : <></>}
      {displayDevicePalette ? <DevicePalette DeviceFunctionality={DeviceFunctionality} deviceTypes={deviceTypes} /> : <></>}

      {canFinishDeviceEditing ? <ConnectionRoutingOptions ManualRouting={routeManually} AutomaticRouting={routeAutomatically} /> : <></>}
      {/* {canFinishDeviceEditing ? <div className="connections-continue-button-container"><IonButton className="connections-continue-button" onClick={finishDeviceEditing}>Start Connection Routing&nbsp;<FontAwesomeIcon icon={faCaretRight}/></IonButton></div> : <></>} */}

      {routeConnection ? <div className="connections-continue-button-container"><IonButton className="connections-continue-button" onClick={() => { setDefineSite(false) }}>Route Connection&nbsp;<FontAwesomeIcon icon={faCaretRight} /></IonButton></div> : <></>}
      {startConnection ? <div className="connections-continue-button-container"><IonButton id="editButton" onClick={editButton}>Edit</IonButton></div> : <></>}
      {startConnection ? <div className="connections-continue-button-container"><IonButton id="finishButton" onClick={displaySummary}>Finish</IonButton></div> : <></>}

      {showRoutingComplete ? <div className="connections-continue-button-container"><IonButton className="connections-continue-button" id="finishRoutingButton" onClick={() => { setShowFinishOptions(true); setShowRoutingComplete(false); }}>Finish Routing</IonButton></div> : <></>}
      {showDetailsComplete ? <div className="connections-continue-button-container"><IonButton className="connections-continue-button" id="finishRoutingButton" onClick={() => { setShowFinishOptions(true); setShowDetailsComplete(false); }}>Finish Registration</IonButton></div> : <></>}
      {showFinishOptions ? <FinishConnectionProcess RunCompletion={runCompletion} /> : <></>};

      <IonAlert
        cssClass="connections-default-alert"
        isOpen={showConfirmConnection}
        onDidDismiss={(ev) => {
          console.log(ev);
          setShowConfirmConnection(false)
          if (ev.detail.role == 'confirm')
            confirmConnection(map)
        }}
        header="Connect to Network?"
        message="Do you wish to connect to the network at this point or continue routing?"
        buttons={[{ "text": "Continue Routing", "role": "cancel" }, { "text": "Connect", "role": "confirm" }]}></IonAlert>
      {/* <AcceptFile useFile={useFile}/> */}
    </>
  );
};


// ==================================================================================================================================================================================================================
// functional components rendering HTML components start

// a popup element to confirm the location chosen by the user
function PopupConfirm(props: any) {
  return (
    <>
      <div>
        <h3 className='popup_confirm'>
          You've selected <strong>{props.address}</strong> as your address.
        </h3>
        <p>
          Please, confirm your location choice.
        </p>

        <div className="connections-button-container">
          <button id="confirmButton" className="connections-popup-button" onClick={() => props.confirm(true)}>Confirm</button>
          <button id="cancelButton" className="connections-popup-button" onClick={() => props.confirm(false)}>Cancel</button>
        </div>
      </div>
    </>
  );
}



// pop up element for the user to choose types of devices to install

function getDeviceForMarker(pin: mapboxgl.Marker): Device | null {
  let d: Device | null = null
  devices.forEach((value: Device, key: string) => {
    if (value.pin == pin)
      d = value;
  });
  return d;
}

// a popup element to inform the user about the connection point
function PopupConnection(props: any) {
  return (
    <>
      <div>
        <h3 className='popup_confirm'>
          <strong>{props.name}</strong>
        </h3>

        <p>
          It's a {props.layer === 'lv' ? 'low voltage' : props.layer}, {props.volt < 1 ? Math.round(props.volt * 1000) + " V" : props.volt + " kV"} cable that goes {props.underground === 'true' ? 'underground.' : 'overground.'}
        </p>
      </div>
    </>
  );
}


// a popup element to inform the user why connection doesn't show as green
function PopupInvalidConnection(props: any) {
  return (
    <>
      <div>
        <h3 className='popup_confirm'>
          <strong>{props.colour === '#ff0000' ? 'ILLEGAL TERRAIN TYPE!' : 'WARNING!'}</strong>
        </h3>

        <p>
          This segment has been highlighted {props.colour === '#ff0000' ? 'red' : 'yellow'} because {props.reason}
        </p>
      </div>
    </>
  );
}



function ConnectionSummary(props: any) {
  return (
    <>
      <div>
        <h3 className='popup_confirm'>
          <strong>Connection Summary</strong>
        </h3>

        <ul>
          <li>cable length: {props.length}m</li>
          <li>number of unusual paths crossed: {props.yellow}</li>
          <li>number of 'forbidden' paths crossed: {props.red}</li>
          <li>approximate connection cost: £{props.cost}</li>
        </ul>
        <div>
          <a download="connection.txt" href={props.file} id='downloadLink'>
            <button id="downloadButton">Save GeoJson</button>
          </a>
        </div>
      </div>
    </>
  );
}



function AcceptFile(props: any) {
  return (
    <>
      <div id='inputFile'>
        <input type='file' name='inputFile' id='uploadedFile' accept='.txt' onChange={(e: any) => props.useFile(e)}></input><br></br> <br></br>
      </div>
    </>
  )
}

interface FinishProcessProps{
  RunCompletion : Function;
}

const FinishConnectionProcess : React.FC<FinishProcessProps> = (props: any) => {

  const modal = useRef<HTMLIonModalElement>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [showFinalisationLoader, setshowFinalisationLoader] = useState(false);
  const [showConfirmComplete, setShowConfirmComplete] = useState(false);

    
    const showItem = (action : string, icon :IconDefinition, label : String) => {
      if (getCapabilities().finishOptions[action]){
        let actionLoading = action+"-loading";
        let actionButton = action+"-loading";
        return (
        <IonItem lines='none' key={action}>
          {!showFinalisationLoader && !showConfirmComplete ? <IonButton className="connections-download-button" id={actionButton} 
          onClick={() => {
            setshowFinalisationLoader(true);
            props.RunCompletion({action}, setShowConfirmComplete)}}><span className="connections-device-icon"><FontAwesomeIcon icon={faCloudUpload}></FontAwesomeIcon></span> {label}</IonButton> : <></>}
        </IonItem>
        )
    }
    }

    return (
      <div>
    <IonModal isOpen={isOpen} ref={modal} className="connections-device-type" onWillDismiss={(ev) => function () {  modal.current?.dismiss(); setIsOpen(false);}}>
    <IonHeader>
      <IonToolbar>
        <IonTitle class="ion-text-center">Finish Registration</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <IonLoading spinner="crescent" id="submission-loading" message="Processing..." isOpen={
        !showConfirmComplete && showFinalisationLoader}></IonLoading>
      {showConfirmComplete ? <IonButton 
        onClick={() => {
          window.location.hash = ACCESS_CREDENTIALS.hash;
          window.location.reload();
        }}
      
      ><span className="connections-device-icon"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></span> Complete</IonButton> : <></>}
      {showItem('downloadCIMXML', faDownload, "Download Complete CIM XML")}
      {showItem('downloadCGMES', faDownload, "Download CGMES 2.4.15 Data")}
      {showItem('saveProject', faCloudUpload, "Save Project")}
      {showItem('submitProject', faCheckSquare, "Submit Application")}
      
    </IonContent>
  </IonModal>
  </div>
    )
};


export default ExploreContainer;


// ==============================================================================================================================================================================================================================
// issues:
// - auto connection doesn't work well when there's no footpath near the starting point just drawing a straight line to the nearest footpath point - maybe make use of the validation function to see how to route or sth, or only connect if the whole segment is green
// - work out how to also query the source outside of the viewport(for finding the actual closest point, not just the visible closest end point) - if possible