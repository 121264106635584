import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import React from "react";

export enum SiteType{ SinglePoint, Area};

export const formData = { 
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    type: '',
    phase: '',
    voltage: '',
    networkKind: '',
    set:false
}; // when adding more voltages to the source, need to update form onClick functions to match voltages from the source to those from the buttons, e.g. primary, secondary etc.

export const FormContext = React.createContext(formData);

export function getVoltageStops() : Array<any>{
    return [
        [
          -1,
          "#000000"
        ],
        [
          0,
          "#0000c8"
        ],
        [
          0.23,
          "#0400c8"
        ],
        [
          0.4,
          "#0700c8"
        ],
        [
          11,
          "#c800c8"
        ],
        [
          20,
          "#c83d24"
        ],
        [
          22,
          "#977708"
        ],
        [
          33,
          "#00c800"
        ],
        [
          66,
          "#cb9e32"
        ],
        [
          132,
          "#a000a0"
        ],
        [
          275,
          "#c80000"
        ],
        [
          400,
          "#0000c8"
        ],
        [
          1000,
          "#a000a0"
        ]
      ];
}

export class DeviceType {
    id : string;
    name : string;
    icon : IconDefinition;
    storage : boolean = false;
    load : boolean = false;
    generation : boolean = false;
  
    constructor(id : string, name : string, icon : IconDefinition, storage : boolean, load : boolean, generation : boolean ){
      this.id = id;
      this.name = name;
      this.icon = icon;
      this.storage = storage;
      this.generation = generation;
      this.load = load;
    }
  
    setStorage(value : boolean){
      this.storage = value;
    }
  
    setLoad(value : boolean){
      this.load = value;
    }
  
    setGeneration(value : boolean){
      this.generation = value;
    }
    
    isStorage() : boolean { return this.storage};
    isGeneration() : boolean { return this.generation};
    isLoad() : boolean { return this.load};
    getID() : string{ return this.id};
    getName() : string{return this.name};
    getIcon() : IconDefinition{ return this.icon};

    toJSON() : any{
        return {
            id : this.id,
            name : this.name,
            load : this.load,
            storage : this.storage,
            generation : this.generation,
        }
    }
  }

  export class Device{
    id : string;
    name : string = '';
    manufacturer : string = '';
    model : string = '';
    pin : mapboxgl.Marker;
    type : DeviceType;
    loadCapacity : number = 0;
    generationCapacity : number = 0;
    storageCapacity : number = 0;
    opened : boolean = false;
  
    constructor(id : string, type : DeviceType, pin : mapboxgl.Marker){
      this.id = id;
      this.type = type;
      this.pin = pin;
      this.name = formData.address1+ " "+type.name;
    }
  
    toJSON() : any{
        return {
            id : this.id,
            name : this.name,
            type : this.type.toJSON(),
            manufacturer : this.manufacturer,
            model : this.model,
            loadCapacity : this.loadCapacity,
            generationCapacity : this.generationCapacity,
            storageCapacity : this.storageCapacity,
            lng : this.pin.getLngLat().lng,
            lat : this.pin.getLngLat().lat
        }
    }

    delete(){
      this.pin.remove();
    }
  
  }
