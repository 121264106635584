import { faCaretRight, faLocationDot, faVectorPolygon } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayEventDetail } from "@ionic/core";
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { useRef } from "react";
import { getCapabilities } from "../App";

interface CharacteristicsProps {
    StatusFunction : Function;
    PinLocation : Function;
    AreaLocation : Function;
}

const StartConnection : React.FC<CharacteristicsProps> = (props: any) =>{
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);
    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
      if (ev.detail.role === 'confirm') {
        
      }
    }
  
    function confirm(onConfirm : any) {
      modal.current?.dismiss(input.current?.value, 'confirm');
      onConfirm();
    }
  
    return (
      <div className="connections-continue-button-container">
        
            <IonButton
              id="connections-start-new"
              className="connections-continue-button"
              onClick={() => {props.StatusFunction(false)}}>Start New Connection&nbsp;
              <FontAwesomeIcon icon={faCaretRight}/>
            </IonButton>
            <IonModal ref={modal} trigger="connections-start-new" onWillDismiss={(ev) => onWillDismiss(ev)}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                </IonButtons>
                <IonTitle>Site Characteristics</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Single Device</IonCardTitle>
                  <IonCardSubtitle>Connect a single device at a point address (e.g. house or small commercial premises)</IonCardSubtitle>
                </IonCardHeader>
  
                <IonCardContent>
                <IonButton className="connections-modal-button" onClick={() => confirm(props.PinLocation)}><FontAwesomeIcon icon={faLocationDot}/>&nbsp;Single Point Location</IonButton>
              </IonCardContent>
            </IonCard>
            {getCapabilities().multiPoint ? <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Multiple Devices</IonCardTitle>
                  <IonCardSubtitle>onnect one or more devices within a larger user-defined area (e.g. industrial site or large commercial premises)</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                <IonButton className="connections-modal-button" onClick={() => confirm(props.AreaLocation)}><FontAwesomeIcon icon={faVectorPolygon}/>&nbsp;User Defined Area</IonButton>
              </IonCardContent>
            </IonCard> : <></>}
            </IonContent>
          </IonModal>
      </div>
    )
    
}

export default StartConnection;