import { IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { logDOM } from '@testing-library/react';
import { useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';

export const LOGO = {src:'assets/images/ogs-dark-on-light.svg'};
export const LOGO_WHITE = {src:'assets/images/ogs-light-on-dark.svg'};

const Home: React.FC = () => {
  return (
    <IonPage id="connections-page">
      <IonHeader>
        <IonToolbar>
          <IonImg src={LOGO_WHITE.src} class={'connections-logo'}/>
          <IonTitle>Grid | Connections</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Grid | Connections</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
